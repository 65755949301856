<template>
  <div class="main-wrapper">
    <div class="pannel-wrapper">
      <div class="area-wrapper">
        <div class="area-wrapper-header">
          <img
            src="@/assets/icons/newEyeOpenIcon.svg"
            alt="eye"
            class="eye"
            @click="hideAllMeasurement"
            v-if="areaMeasurIsShown && !anonymousUser"
          />
          <img
            src="@/assets/icons/newEyeClosedIcon.svg"
            alt="eye"
            class="eye-closed"
            @click="showAllMeasurement"
            v-if="!areaMeasurIsShown && !anonymousUser"
          />
          <div
            class="section-header-right-side"
            @click="toggleAreas"
            :class="{ inactiveGray: !areaMeasurIsShown }"
          >
            <p class="cluster-title">
              {{ $t("sidebar.areas") }}
            </p>
            <img
              src="@/assets/icons/newArrowUp.svg"
              alt="arrow"
              class="arrow"
              v-show="showAreasbool"
            />
            <img
              src="@/assets/icons/newArrowDown.svg"
              alt="arrow"
              class="arrow"
              v-show="!showAreasbool"
            />
          </div>
        </div>
        <div v-if="showAreasbool" class="area-details-box">
          <div
            class="area-inside-wrapper"
            v-for="(area, index) in completedAreaMeasurements"
            :key="index"
          >
            <div
              class="area-header"
              :class="{
                active: index == selectedArea && area.show,
                grayClass: !areaMeasurIsShown,
                expand: index == selectedArea,
              }"
            >
              <div
                @click="showareaDetails(area, index, true)"
                class="left-text"
              >
                <p>{{ $t("sidebar.area") }} {{ index + 1 }}</p>
              </div>
              <div
                class="anotation-image-wrapper"
                :class="{
                  'hidden-areas':
                    !area.show && areaMeasurIsShown && !anonymousUser,
                  'shown-areas':
                    area.show && areaMeasurIsShown && !anonymousUser,
                }"
              >
                <img
                  src="@/assets/icons/newTrashIcon.svg"
                  alt="trash"
                  class="trash"
                  v-if="showTrashIcon && area.show && !anonymousUser"
                  @click="handleAreaMeasurementDelete(area)"
                />
                <img
                  src="@/assets/icons/newEyeOpenIcon.svg"
                  alt="eye"
                  class="eye"
                  v-if="area.show && areaMeasurIsShown && !anonymousUser"
                  @click="hideThisArea(area, index)"
                />
                <img
                  src="@/assets/icons/newEyeClosedIcon.svg"
                  alt="eye"
                  class="eye-closed"
                  @click="showThisArea(area, index)"
                  v-if="!area.show && !areaMeasurIsShown && !anonymousUser"
                />
                <img
                  src="@/assets/icons/newEyeOpenIcon.svg"
                  alt="eye"
                  class="eye"
                  v-if="area.show && !areaMeasurIsShown && !anonymousUser"
                  @click="hideThisArea(area, index)"
                />
                <img
                  src="@/assets/icons/newEyeClosedIcon.svg"
                  alt="eye"
                  class="eye-closed"
                  @click="showThisArea(area, index)"
                  v-if="!area.show && areaMeasurIsShown && !anonymousUser"
                />
              </div>
            </div>
            <div class="area-details" v-if="index == selectedArea">
              <div class="details-text">
                <p>{{ $t("sidebar.area") }}</p>
                <span
                  >{{
                    isNaN(area.surfaceArea)
                      ? area.surfaceArea
                      : $n(Number(area.surfaceArea), "decimal")
                  }}m²</span
                >
              </div>
              <div class="details-text">
                <p>{{ $t("sidebar.slope") }}</p>
                <span
                  >{{
                    isNaN(area.angle)
                      ? area.angle
                      : $n(Number(area.angle), "decimal")
                  }}°</span
                >
              </div>
              <div class="details-text">
                <p>{{ $t("sidebar.perimeter") }}</p>
                <span>{{ $n(Number(activeAreaPerimeter), "decimal") }}m</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, nextTick, watch, computed } from "vue";
import API from "@/api/API";

import {
  hideSingleMeasurementArea,
  showSingleMeasurementArea,
} from "@/modules/areaMeasurement.js";

const emits = defineEmits(["setColapsedSections"]);

const props = defineProps([
  "completedAreaMeasurements",
  "areaDetails",
  "hideAllMeasurementAreas",
  "showAllMeasurementAreas",
  "sample",
  "config",
  "setVisibilityInStore",
  "objectIsloaded",
  "selectedFromSidebarEvent",
  "removeSelectedMeasurementArea",
  "detectedMeasurementArea",
  "unselectFromSidebar",
  "anonymousUser",
  "active",
]);

const showAreasbool = ref(false);
const selectedArea = ref(null);
const showTrashIcon = ref(true);

function toggleAreas() {
  if (props.areaDetails.length) {
    showAreasbool.value = !showAreasbool.value;
    emits("setColapsedSections", "areaComponent");
  }
  if (showAreasbool.value) {
    selectedArea.value = null;
  }
}

function colapseFromParent() {
  if (props.areaDetails.length) {
    showAreasbool.value = false;
    previousSelectedArea.value = null;
    if (props.completedAreaMeasurements[selectedArea.value]) {
      props.unselectFromSidebar(
        props.completedAreaMeasurements[selectedArea.value]
      );
    }
  }
}

function showareaDetails(area, number, fromSidebar = false) {
  if (!area.show || !areaMeasurIsShown.value) {
    selectedArea.value = null;
    props.unselectFromSidebar(area);
    return;
  }
  if (!showAreasbool.value) {
    showAreasbool.value = true;
    emits("setColapsedSections", "areaComponent");
  }
  sumUpPerimeter(area);

  if (selectedArea.value != number) {
    selectedArea.value = number;
    if (fromSidebar) {
      props.selectedFromSidebarEvent(area.label.id);
    }
  } else {
    if (fromSidebar) {
      selectedArea.value = null;
      props.unselectFromSidebar(area);
    }
  }
}

const activeAreaPerimeter = ref(null);

function sumUpPerimeter(area) {
  const sum = ref(0);
  area.lines.forEach((element) => {
    sum.value = sum.value + parseFloat(element.label.element.distance);
  });

  activeAreaPerimeter.value = sum.value.toFixed(2);
}

const areaMeasurIsShown = ref(false);

function hideAllMeasurement() {
  if (props.active === 4) {
    return;
  }
  if (props.areaDetails[selectedArea.value]) {
    props.unselectFromSidebar(props.areaDetails[selectedArea.value]);
  }
  selectedArea.value = null;

  if (props.areaDetails.length) {
    props.hideAllMeasurementAreas();
    areaMeasurIsShown.value = false;
    props.setVisibilityInStore("measurements_visible", false);
  }
  showTrashIcon.value = false;
}
function showAllMeasurement() {
  props.showAllMeasurementAreas();
  areaMeasurIsShown.value = true;
  props.setVisibilityInStore("measurements_visible", true);
  showTrashIcon.value = true;
}

const hiddenArea = ref(null);

function hideThisArea(area, index) {
  selectedArea.value = null;
  props.unselectFromSidebar(area);

  area.show = false;
  if (areaMeasurIsShown.value) {
    hideSingleMeasurementArea(area);
  }
  hiddenArea.value = index;
  const show = false;
  setAreaAppearance(area.id, show);
}
function showThisArea(area, index) {
  area.show = true;
  if (areaMeasurIsShown.value) {
    showSingleMeasurementArea(area);
  }

  hiddenArea.value = null;
  const show = true;
  setAreaAppearance(area.id, show);
}

async function setAreaAppearance(id, show) {
  if (props.sample) {
    return;
  }
  return await API.airteam3DViewer.patchObject(id, show);
}

function setAreaAppearanceButton() {
  for (const area of props.areaDetails) {
    if (area.show) {
      areaMeasurIsShown.value = true;
      break; // Exit the loop
    }
  }
}

function deselectSidebarArea() {
  if (selectedArea.value !== null) {
    selectedArea.value = null;
  }
}

let previousSelectedArea = ref(null);
function selectMeasurementAreaFromCanvas(indexOfArea) {
  if (selectedArea.value != indexOfArea) {
    selectedArea.value = indexOfArea;
  }
  if (props.areaDetails.length) {
    if (!props.areaDetails[indexOfArea]?.show) {
      return;
    }
    showAreasbool.value = true;
    if (previousSelectedArea.value != indexOfArea) {
      emits("setColapsedSections", "areaComponent");
      previousSelectedArea.value = indexOfArea;
    }
    // Add scroll behavior
    setTimeout(() => {
      const selectedElement = document.querySelector(
        ".area-header.active.expand"
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }, 100);
  }
}

function handleCreateAreaMeasurement() {
  areaMeasurIsShown.value = true;
  if (props.areaDetails.length) {
    showAreasbool.value = true;
  }
  if (showAreasbool.value) {
    emits("setColapsedSections", "areaComponent");
    props.setVisibilityInStore("measurements_visible", true);

    const lastArea = props.areaDetails[props.areaDetails.length - 1];
    showThisArea(lastArea, props.areaDetails.length - 1);
    showareaDetails(lastArea, props.areaDetails.length - 1);

    // Add scroll behavior
    setTimeout(() => {
      const selectedElement = document.querySelector(
        ".area-header.active.expand"
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }, 100);
  }
}
defineExpose({
  colapseFromParent,
  handleCreateAreaMeasurement,
  showMeasurementAreaFlag,
  deselectSidebarArea,
  selectMeasurementAreaFromCanvas,
});

function handleAreaMeasurementDelete(area) {
  props.selectedFromSidebarEvent(area.label.id, false);
  props.removeSelectedMeasurementArea();
}

function showMeasurementAreaFlag() {
  if (props.areaDetails.length) {
    areaMeasurIsShown.value = true;
    showAllMeasurement();
    props.setVisibilityInStore("measurements_visible", true);
  }
}

watch(
  () => props.completedAreaMeasurements[selectedArea.value]?.surfaceArea,
  (newValue) => {
    if (newValue) {
      sumUpPerimeter(props.completedAreaMeasurements[selectedArea.value]);
    }
  }
);

// Watch for changes in props.areaDetails
watch(
  () => props.areaDetails.length,
  (newLength) => {
    if (newLength > 0) {
      setAreaAppearanceButton();
    }
  }
);
// Watch for changes in props.detectedMeasurementArea
watch(
  () => props.detectedMeasurementArea,
  (newValue) => {
    if (newValue) {
      // Find the selected area
      let selectedArea = props.areaDetails.filter(
        (element) => element.label.id == newValue.id + 1
      );

      // Get the index of the selected area
      let selectedIndex = props.areaDetails.findIndex(
        (element) => element.label.id == newValue.id + 1
      );

      // Show the selected area details
      showareaDetails(...selectedArea, selectedIndex);

      // Add scroll behavior
      setTimeout(() => {
        const selectedElement = document.querySelector(
          ".area-header.active.expand"
        );
        if (selectedElement) {
          selectedElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      }, 100);
    } else {
      selectedArea.value = null;
    }
  }
);

watch(
  () => props.objectIsloaded,
  (newValue) => {
    if (newValue) {
      if (props.config.measurements_visible) {
        areaMeasurIsShown.value = props.config.measurements_visible;
        showAllMeasurement();
      } else {
        hideAllMeasurement();
      }
    }
  }
);
</script>

<style lang="scss" scoped>
.main-wrapper {
  .pannel-wrapper {
    max-height: 31rem;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .area-wrapper {
      .area-wrapper-header {
        position: sticky;
        top: 0;
        z-index: 1;
        background: #333537;
        max-height: 30px;
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 1rem 0;
        .section-header-right-side {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
        }
        .section-header-right-side.inactiveGray {
          p {
            color: gray;
          }
          img {
            filter: grayscale(1) brightness(0.5); /* Initial gray color */
          }
        }
        p {
          font-family: "Roboto";
          font-size: 14px;
          font-style: normal;
          font-weight: bold;
          line-height: 19px;
          color: white;
          letter-spacing: 0.03em;
        }
        img {
          height: 12px;
          width: 18px;
          cursor: pointer;
        }
        .arrow,
        .trash {
          height: 20px;
          width: 20px;
        }
      }
      .area-details-box {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 12px;
        margin-top: 6px;

        .area-inside-wrapper {
          .area-header.active.expand {
            border: 1px solid #23e7a5;
            border-radius: 10px 10px 0 0;
            border-bottom: none;
            margin-bottom: 1px;
            .anotation-image-wrapper {
              display: flex;
              gap: 1rem;
            }
          }
          .area-header.active:hover {
            .anotation-image-wrapper {
              img {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
          }
          .area-header.expand {
            border: none;
            margin-bottom: 1px;
            border-radius: 10px 10px 0 0;
          }
          .area-header:not(.active):hover {
            .anotation-image-wrapper.shown-areas {
              display: flex;
              gap: 1rem;
              .trash {
                filter: grayscale(1) brightness(1.7);
              }
              .eye {
                filter: grayscale(1) brightness(0.7);
              }
              .eye-closed {
                filter: grayscale(1) brightness(1.3);
              }
              img:hover {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
          }
          .area-header {
            background: #27292c;
            max-height: 30px;
            // border: 1px solid #3b3c3d;
            display: flex;
            gap: 1rem;
            align-items: center;
            padding: 1rem 0.5rem;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1px;
            .left-text {
              flex: 1;
              min-width: 0;
            }
            .anotation-image-wrapper {
              display: none;
            }
            .anotation-image-wrapper.hidden-areas {
              display: flex;
              img {
                filter: grayscale(1) brightness(1);
              }
              .eye-closed {
                filter: grayscale(1) brightness(1.3);
              }
              img:hover {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
            p.grayCustome {
              color: gray;
            }
            p {
              color: white;
              cursor: pointer;
            }
            img {
              width: 18px;
              height: 18px;
              cursor: pointer;
              filter: brightness(0) invert(1);
            }
          }
          .area-details {
            background: #27292c;
            border: 1px solid #23e7a5;
            border-top: none;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 5px 5px 8px 10px;
            .details-text {
              display: flex;
              justify-content: space-between;
            }
          }

          .area-header.grayClass {
            margin-bottom: 1px;
            p {
              color: gray;
            }
            .eye {
              filter: grayscale(1) brightness(0.7);
            }
            .eye-closed {
              filter: grayscale(1) brightness(1.3);
            }
            img:hover {
              filter: brightness(0) invert(1); /* White color on hover */
            }
            .trash {
              display: none;
            }
            .anotation-image-wrapper {
              display: flex;
              gap: 1rem;
            }
          }
          .area-header.active.grayClass {
            border: none;
            .anotation-image-wrapper {
              img {
                filter: brightness(0) invert(0.5); /* gray color  */
              }
              img:hover {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
          }
          .area-details.grayClass {
            border: none;

            p,
            span {
              color: gray;
            }
          }
        }
      }
    }
  }
}
.eye:hover {
  filter: brightness(0) invert(1); /* White color on hover */
}
.eye-closed {
  filter: grayscale(1) brightness(1);
}

@media (max-width: 1600px) {
  .main-wrapper {
    .pannel-wrapper {
      .area-wrapper {
        .area-details-box {
          .area-inside-wrapper {
            .area-details {
              border: 1px solid #23e7a5;
              border-top: none;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              padding: 5px 5px 8px 10px;
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
