<template>
  <a
    v-if="
      (measurements.length > 0 ||
        measurementAreas.length > 0 ||
        areas.length > 0) &&
      areAllObjectsFinished
    "
    class="export-annotation"
    @click="getExcel"
    :title="$t('sidebar.exportAnnotationTooltip')"
    >{{ $t("sidebar.exportAnnotation") }}</a
  >
</template>
<script>
import API from "@/api/API.js";
import * as areaMethods from "@/modules/area.js";
import * as commonMethods from "@/modules/common.js";
import * as panelMethods from "@/modules/panel.js";
import * as THREE from "three";
import * as measurementMethods from "@/modules/measurement.js";
import { AREA, SOLAR_GROUP } from "@/constants.js";
import { event } from "vue-gtag";
import { isProduction } from "@/utils/env.js";
import moment from "moment";

export default {
  name: "ExcelExport",
  props: [
    "projectId",
    "areas",
    "measurements",
    "measurementAreas",
    "panelTypes",
  ],
  computed: {
    areAllObjectsFinished() {
      const areAllAreasFinished =
        this.areas.filter((i) => !i.closed).length === 0;
      const areAllMeasurementsFinished =
        this.measurements.filter((i) => !i?.secondPoint).length === 0;
      const areAllMeasurementAreasFinished =
        this.measurementAreas.filter((i) => !i.closed).length === 0;

      return (
        areAllAreasFinished &&
        areAllMeasurementsFinished &&
        areAllMeasurementAreasFinished
      );
    },
    configState() {
      return this.$store.state.section.config;
    },
  },
  methods: {
    ...Object.fromEntries([
      ...Object.entries(commonMethods),
      ...Object.entries(areaMethods),
      ...Object.entries(measurementMethods),
      ...Object.entries(panelMethods),
    ]),
    async getExcel() {
      const lang = this.$i18n.locale;
      const distancesData = this.getMeasurementsData(this.measurements);
      const measurementAreasData = this.getMeasurementAreasData(
        this.measurementAreas
      );
      const areasData = this.getAreasData(this.areas);
      const data = {
        lang,
        projectId: this.projectId,
        distances: distancesData,
        measurementAreas: measurementAreasData,
        areas: areasData,
      };

      API.airteam3DViewer.getXlsx(data).then((response) => {
        const filename = `ID${this.projectId}_${this.$t(
          "sidebar.annotations"
        )}.xlsx`;
        const companyName = response.headers["companyname"];
        event("xlsx_data_requested_ff", {
          event_label: "Excel Data Downloaded",
          date: moment().format("DD.MM.YY + HH.mm"),
          project_id: this.projectId,
          file_name: filename,
          company_name: companyName,
          environment: isProduction ? "production" : "staging",
          customer_type: this.activeUserIsEmployee ? "employee" : "customer",
        });

        const blob = new Blob([response.data]);
        // Force browser to trigger download even when ThreeJS transform controls are active by handling download in separate context
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        document.body.appendChild(iframe);
        iframe.contentWindow.document.write(`
          <a id="download" download="${filename}" href="${URL.createObjectURL(
          blob
        )}"></a>
        `);
        iframe.contentWindow.document.getElementById("download").click();
        setTimeout(() => document.body.removeChild(iframe), 100);
      });
    },
    getVerticalComponent(distance) {
      const verticalFirstPoint = distance.firstPoint.position.clone();
      const verticalSecondPoint = new THREE.Vector3(
        distance.firstPoint.position.x,
        distance.secondPoint.position.y,
        distance.firstPoint.position.z
      );
      return verticalSecondPoint.distanceTo(verticalFirstPoint);
    },
    getHorizontalComponent(distance) {
      const horizontalFirstPoint = new THREE.Vector3(
        distance.firstPoint.position.x,
        distance.secondPoint.position.y,
        distance.firstPoint.position.z
      );
      const horizontalSecondPoint = new THREE.Vector3(
        distance.secondPoint.position.x,
        distance.secondPoint.position.y,
        distance.secondPoint.position.z
      );
      return horizontalSecondPoint.distanceTo(horizontalFirstPoint);
    },
    getMeasurementsData(measurements) {
      const distancesData = [];

      if (!this.configState.distances_visible) return distancesData;

      measurements.forEach((distance, index) => {
        if (distance.show) {
          const verticalComponent = this.getVerticalComponent(distance);
          const horizontalComponent = this.getHorizontalComponent(distance);

          const data = {
            id: index + 1,
            length: distance.distance,
            horizontalComponent: horizontalComponent.toFixed(2),
            verticalComponent: verticalComponent.toFixed(2),
          };
          distancesData.push(data);
        }
      });
      return distancesData;
    },
    getMeasurementAreasData(measurementAreas) {
      const measurementAreasData = [];

      if (!this.configState.measurements_visible) return measurementAreasData;

      measurementAreas.forEach((area, index) => {
        if (area.show) {
          const areaAngle = parseFloat(area?.angle);
          const data = {
            id: index + 1,
            area: area.surfaceArea,
            slope: Number.isNaN(areaAngle) ? 0 : area.angle,
            perimeter: area.lines
              .map((line) => line.label.element.distance)
              .reduce((partialSum, a) => partialSum + a, 0)
              .toFixed(2),
          };
          measurementAreasData.push(data);
        }
      });
      return measurementAreasData;
    },
    getPanel(area) {
      return this.panelTypes.find((panel) => panel.id === area.panelId);
    },
    getAreasData(areas) {
      const areasData = [];

      if (!this.configState.solar_groups_visible) return areasData;

      areas.forEach((area, index) => {
        if (area.show) {
          const panel = this.getPanel(area);
          const panelsCount = this.getAreaPanelsCount(area);
          const totalAreaOfSolarPanels = this.calculateVisiblePanelsArea(area);

          const panelDimensions = panel
            ? `${panel.size.height * 1000}mm x ${panel.size.width * 1000}mm`
            : "";

          const data = {
            id: index + 1,
            area: this.calculateSurfaceArea(area),
            slope: area.angle,
            panelsCount,
            panelType: panel?.name || "",
            dimension: panelDimensions,
            solarPanelsTotalArea: totalAreaOfSolarPanels,
          };
          areasData.push(data);
        }
      });
      return areasData;
    },
  },
};
</script>
<style>
.export-annotation {
  bottom: 10px;
  position: absolute;
  color: #1fdb9b !important;
  text-decoration: underline !important;
}
.export-annotation:hover {
  cursor: pointer;
}
</style>
