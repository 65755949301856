<template>
  <div class="main-wrapper">
    <div class="pannel-wrapper">
      <div class="distance-wrapper">
        <div class="distance-wrapper-header">
          <img
            src="@/assets/icons/newEyeOpenIcon.svg"
            alt="eye"
            class="eye"
            @click="hideAllDistance"
            v-if="allDistancesareHidden && !anonymousUser"
          />
          <img
            src="@/assets/icons/newEyeClosedIcon.svg"
            alt="eye"
            class="eye-closed"
            @click="showAllDistance"
            v-if="!allDistancesareHidden && !anonymousUser"
          />
          <div
            class="section-header-right-side"
            @click="toggleDistances"
            :class="{ inactiveGray: !allDistancesareHidden }"
          >
            <p class="cluster-title">{{ $t("sidebar.distances") }}</p>
            <img
              src="@/assets/icons/newArrowUp.svg"
              alt="arrow"
              class="arrow"
              v-show="showDistances"
            />
            <img
              src="@/assets/icons/newArrowDown.svg"
              alt="arrow"
              class="arrow"
              v-show="!showDistances"
            />
          </div>
        </div>
        <div v-if="showDistances" class="distance-details-box">
          <div
            class="distance-inside-wrapper"
            v-for="(distance, index) in completedDistances"
            :key="index"
          >
            <div
              class="distance-header"
              :class="{
                active: index == selectedDistance && distance.show,
                grayClass: !allDistancesareHidden,
                expand: index == selectedDistance,
              }"
            >
              <div
                @click="showdistanceDetails(distance, index)"
                class="left-text"
              >
                <p>{{ $t("sidebar.distance") }} {{ index + 1 }}</p>
              </div>
              <div
                class="anotation-image-wrapper"
                :class="{
                  'hidden-distance':
                    !distance.show && allDistancesareHidden && !anonymousUser,
                }"
              >
                <img
                  src="@/assets/icons/newTrashIcon.svg"
                  alt="trash"
                  class="trash"
                  v-if="showTrashIcon && distance.show && !anonymousUser"
                  @click="handleDelete(distance, index)"
                />

                <img
                  src="@/assets/icons/newEyeOpenIcon.svg"
                  alt="eye"
                  class="eye"
                  @click="hideSelectedDistance(distance, index)"
                  v-if="
                    distance.show && allDistancesareHidden && !anonymousUser
                  "
                />
                <img
                  src="@/assets/icons/newEyeClosedIcon.svg"
                  alt="eye"
                  class="eye-closed"
                  @click="showSelectedDistance(distance, index)"
                  v-if="
                    !distance.show && !allDistancesareHidden && !anonymousUser
                  "
                />
                <img
                  src="@/assets/icons/newEyeOpenIcon.svg"
                  alt="eye"
                  class="eye"
                  @click="hideSelectedDistance(distance, index)"
                  v-if="
                    distance.show && !allDistancesareHidden && !anonymousUser
                  "
                />
                <img
                  src="@/assets/icons/newEyeClosedIcon.svg"
                  alt="eye"
                  class="eye-closed"
                  @click="showSelectedDistance(distance, index)"
                  v-if="
                    !distance.show && allDistancesareHidden && !anonymousUser
                  "
                />
              </div>
            </div>
            <div class="distance-details" v-if="index == selectedDistance">
              <div class="details-text">
                <span>{{ $n(distance.distance,"decimal") }}m</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import API from "@/api/API";

import {
  hideSelectedMeasurement,
  showSelectedMeasurement,
} from "@/modules/measurement.js";

const props = defineProps([
  "completedDistances",
  "distancesDetails",
  "hideMeasurements",
  "showMeasurements",
  "sample",
  "config",
  "setVisibilityInStore",
  "objectIsloaded",
  "deleteMeasurement",
  "anonymousUser",
  "active",
]);

const emits = defineEmits(["updateSelectedDistance", "setColapsedSections"]);

const showDistances = ref(false);
const selectedDistance = ref(null);

const showTrashIcon = ref(true);

function toggleDistances() {
  if (props.distancesDetails.length) {
    showDistances.value = !showDistances.value;
  }
  if (showDistances.value) {
    selectedDistance.value = null;
    emits("setColapsedSections", "distanceComponent");
  }
}

function colapseFromParent() {
  if (props.distancesDetails.length) {
    showDistances.value = false;
    // i think we don't need this but needs to be checked once final ticket is done, will remove this part together with the updateSelectedDistance logic later
    // emits("updateSelectedDistance", "");
    selectedDistance.value = null;
  }
}

function showdistanceDetails(distance, number) {
  if (!distance.show || !allDistancesareHidden.value) {
    return;
  }
  if (selectedDistance.value != number) {
    emits("updateSelectedDistance", distance.line.id);
    selectedDistance.value = number;
  } else {
    emits("updateSelectedDistance", "");
    selectedDistance.value = null;
  }
}

const allDistancesareHidden = ref(false);
const hidenDistance = ref(null);

function hideAllDistance() {
  if (props.distancesDetails.length && props.active !== 1) {
    deselectSidebarDistances();
    props.hideMeasurements();

    allDistancesareHidden.value = false;
    props.setVisibilityInStore("distances_visible", false);
    showTrashIcon.value = false;
  }
}
function showAllDistance() {
  props.showMeasurements();
  allDistancesareHidden.value = true;
  props.setVisibilityInStore("distances_visible", true);
  showTrashIcon.value = true;
}

function hideSelectedDistance(distance, index) {
  distance.show = false;
  if (selectedDistance.value) {
    selectedDistance.value = null;
    emits("updateSelectedDistance", "");
  }

  if (allDistancesareHidden.value) {
    hideSelectedMeasurement(distance);
  }
  hidenDistance.value = index;
  const show = false;
  setDistanceAppearance(distance.id, show);
}
function showSelectedDistance(distance, index) {
  distance.show = true;
  if (allDistancesareHidden.value) {
    showSelectedMeasurement(distance);
  }

  hidenDistance.value = null;
  const show = true;
  if (distance.id) setDistanceAppearance(distance.id, show);
}

async function setDistanceAppearance(id, show) {
  if (props.sample) {
    return;
  }
  return await API.airteam3DViewer.patchObject(id, show);
}

function setDistanceAppearanceButton() {
  for (const distance of props.distancesDetails) {
    if (distance.show) {
      allDistancesareHidden.value = true;
      break; // Exit the loop
    }
  }
}

function handleCreateDistance() {
  allDistancesareHidden.value = true;
  if (props.distancesDetails.length) {
    showDistances.value = true;
  }
  if (showDistances.value) {
    emits("setColapsedSections", "distanceComponent");
    props.setVisibilityInStore("distances_visible", true);

    const lastDistance =
      props.distancesDetails[props.distancesDetails.length - 1];
    showSelectedDistance(lastDistance, props.distancesDetails.length - 1);

    // Add scroll behavior
    setTimeout(() => {
      const selectedElement = document.querySelector(
        ".distance-header.active.expand"
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }, 100);
  }
}

function selectMeasurementFromCanvas(indexOfDistance) {
  if (selectedDistance.value != indexOfDistance) {
    selectedDistance.value = indexOfDistance;
  }
  if (props.distancesDetails.length) {
    showDistances.value = true;
    emits("setColapsedSections", "distanceComponent");

    // Add scroll behavior
    setTimeout(() => {
      const selectedElement = document.querySelector(
        ".distance-header.active.expand"
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }, 100);
  }
}
function deselectSidebarDistances() {
  selectedDistance.value = null;
}

defineExpose({
  colapseFromParent,
  handleCreateDistance,
  selectMeasurementFromCanvas,
  deselectSidebarDistances,
  showDistanceFlag,
});

function handleDelete(distance, index) {
  props.deleteMeasurement(distance.line.id);
}
function showDistanceFlag() {
  if (props.distancesDetails.length) {
    allDistancesareHidden.value = true;
    showAllDistance();
    props.setVisibilityInStore("distances_visible", true);
  }
}

// Watch for changes in props.areaDetails
watch(
  () => props.distancesDetails.length,
  (newLength) => {
    if (newLength > 0) {
      setDistanceAppearanceButton();
    }
  }
);
watch(
  () => props.objectIsloaded,
  (newValue) => {
    if (newValue) {
      if (props.config.distances_visible) {
        allDistancesareHidden.value = !props.config.distances_visible;
        showAllDistance();
      } else {
        hideAllDistance();
      }
    }
  }
);
</script>

<style lang="scss" scoped>
.main-wrapper {
  .pannel-wrapper {
    max-height: 31rem;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .distance-wrapper {
      .distance-wrapper-header {
        position: sticky;
        top: 0;
        z-index: 1;
        background: #333537;
        max-height: 30px;
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 1rem 0;
        .section-header-right-side {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
        }
        .section-header-right-side.inactiveGray {
          p {
            color: gray;
          }
          img {
            filter: grayscale(1) brightness(0.5); /* Initial gray color */
          }
        }

        p {
          font-family: "Roboto";
          font-size: 14px;
          font-style: normal;
          font-weight: bold;
          line-height: 19px;
          color: white;
          letter-spacing: 0.03em;
        }
        img {
          height: 12px;
          width: 18px;
          cursor: pointer;
        }
        .trash,
        .arrow {
          height: 20px;
          width: 20px;
        }
        .trash.hideImage {
          display: none;
        }
      }
      .distance-details-box {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 12px;
        margin-top: 6px;

        .distance-inside-wrapper {
          .distance-header.active.expand {
            border: 1px solid #23e7a5;
            border-radius: 10px 10px 0 0;
            border-bottom: none;

            margin-bottom: 1px;
            .anotation-image-wrapper {
              display: flex;
              gap: 1rem;
            }
          }
          .distance-header.active:hover {
            .anotation-image-wrapper {
              img {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
          }
          .distance-header.expand {
            border: none;
            margin-bottom: 1px;
            border-radius: 10px 10px 0 0;
          }
          .distance-header:not(.active):hover {
            .anotation-image-wrapper {
              display: flex;
              gap: 1rem;
              .trash {
                filter: grayscale(1) brightness(1.7);
              }
              .eye {
                filter: grayscale(1) brightness(0.7);
              }
              .eye-closed {
                filter: grayscale(1) brightness(1.3);
              }
              img:hover {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
          }
          .distance-header {
            background: #27292c;
            border: 1px solid #3b3c3d;
            display: flex;
            gap: 1rem;
            align-items: center;
            padding: 1rem 0.5rem;
            max-height: 30px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            p.grayCustome {
              color: gray;
            }
            .left-text {
              flex: 1;
              min-width: 0;
            }
            .anotation-image-wrapper {
              display: none;
            }
            .anotation-image-wrapper.hidden-distance {
              display: flex;
              img {
                filter: grayscale(1) brightness(1);
              }
              .eye-closed {
                filter: grayscale(1) brightness(1.3);
              }
              img:hover {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }

            p {
              cursor: pointer;
              color: white;
            }
            img {
              width: 18px;
              height: 18px;
              cursor: pointer;
              filter: brightness(0) invert(1);
            }
          }
          .distance-details {
            background: #27292c;
            border: 1px solid #23e7a5;
            border-top: none;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 5px 5px 8px 10px;
            .details-text {
              display: flex;
              justify-content: space-between;
            }
          }

          .distance-header.grayClass {
            margin-bottom: 1px;
            p {
              color: gray;
            }
            .eye {
              filter: grayscale(1) brightness(0.7);
            }
            .eye-closed {
              filter: grayscale(1) brightness(1.3);
            }
            img:hover {
              filter: brightness(0) invert(1); /* White color on hover */
            }
            .trash {
              display: none;
            }
            .anotation-image-wrapper {
              display: flex;
              gap: 1rem;
            }
          }
          .distance-header.active.grayClass {
            border: none;
            .anotation-image-wrapper {
              img {
                filter: brightness(0) invert(0.5); /* gray color  */
              }
              img:hover {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
          }
          .distance-details.grayClass {
            border: none;

            span {
              color: gray;
            }
          }
        }
      }
    }
  }
}

.eye:hover {
  filter: brightness(0) invert(1); /* White color on hover */
}
.eye-closed {
  filter: grayscale(1) brightness(1);
}
@media (max-width: 1600px) {
  .main-wrapper {
    .pannel-wrapper {
      .distance-wrapper {
        .distance-details-box {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 12px;
          margin-top: 6px;

          .distance-inside-wrapper {
            .distance-details {
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
