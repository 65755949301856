import * as THREE from "three";
import { toRaw } from "vue";
import API from "@/api/API";
import {
  MOUNTING_SURFACE_COLOR,
  RENDERING_ORDER,
  SOLAR_POINT_COLOR,
  MARGIN_COLOR,
  SELECTED_LINE_COLOR,
  DEFAULT_HORIZONTAL_SPACING,
  DEFAULT_VERTICAL_SPACING,
  DEFAULT_MARGIN,
  DEFAULT_OFFSET,
} from "../constants";
import { convertToCentimeters } from "../utils/units";
import { roundVector } from "../utils/round-vector.js";

export const hideAreas = function (hidePanels = true) {
  const filteredAreas = this.areas.filter((area) => area.plane);

  for (let i = 0; i < filteredAreas.length; i++) {
    this.hideSingleArea(filteredAreas[i], hidePanels);
  }
};

export const hideSingleArea = function (areaToHide, hidePanels) {
  if (areaToHide && areaToHide.plane) {
    this.hideRestrictedArea(areaToHide);
    areaToHide.plane.visible = false;
    if (areaToHide.moveGridLabel) areaToHide.moveGridLabel.visible = false;
    if (areaToHide.rotateGridLabel) areaToHide.rotateGridLabel.visible = false;
    areaToHide.pointInstancedMesh.visible = false;
    areaToHide.midpointInstancedMesh.visible = false;
    areaToHide.combinedLine.visible = false;

    if (areaToHide.innerPlane) {
      areaToHide.innerPlane.material.opacity = 0.0;
      areaToHide.innerPlane.visible = false;
    }

    if (hidePanels) {
      areaToHide.panels.forEach((panel) => (panel.plane.visible = false));
      if (areaToHide.type == "SOLAR_GROUP" && areaToHide.instancedMesh) {
        areaToHide.instancedMesh.visible = false;
      }
      if (areaToHide.verticalPanelInstancedMesh) {
        areaToHide.verticalPanelInstancedMesh.visible = false;
      }
      if (areaToHide.horizontalPanelInstancedMesh) {
        areaToHide.horizontalPanelInstancedMesh.visible = false;
      }
    }
  }
};

export const setPanelsOpaque = function () {
  this.setPanelsOpacity(1.0);
};

export const setPanelsTransparent = function () {
  this.setPanelsOpacity(0.5);
};

export const setPanelsOpacity = function (opacity) {
  this.areas
    .filter((area) => area.instancedMesh)
    .forEach((area) => {
      area.instancedMesh.material.opacity = opacity;

      if (area.verticalPanelInstancedMesh)
        area.verticalPanelInstancedMesh.material.opacity = opacity;

      if (area.horizontalPanelInstancedMesh)
        area.horizontalPanelInstancedMesh.material.opacity = opacity;
    });
};

export const showAreas = function () {
  const filteredAreas = this.areas.filter((area) => area.plane && area.show);
  for (let i = 0; i < filteredAreas.length; i++) {
    this.showSingleArea(filteredAreas[i]);
  }
};

export const showSingleArea = function (areaToShow) {
  if (areaToShow && areaToShow.plane) {
    if (areaToShow.verticalPanelInstancedMesh) {
      areaToShow.verticalPanelInstancedMesh.visible = true;
    }
    if (areaToShow.horizontalPanelInstancedMesh) {
      areaToShow.horizontalPanelInstancedMesh.visible = true;
    }
    if (this.selectArea && this.active === 2) {
      this.shoWRestrictedArea(areaToShow);
    }
    areaToShow.plane.visible = true;
    if (areaToShow.innerPlane) areaToShow.innerPlane.visible = true;

    if (this.active !== 0 && this.active !== 1 && this.active !== 4) {
      areaToShow.pointInstancedMesh.visible = true;
      areaToShow.midpointInstancedMesh.visible = true;
      areaToShow.combinedLine.visible = true;
    }

    areaToShow.panels.forEach((panel) => (panel.plane.visible = true));
    if (areaToShow.type == "SOLAR_GROUP" && areaToShow.instancedMesh) {
      areaToShow.instancedMesh.visible = true;
    }
  }
};

export const removeUnfinshedArea = function () {
  const currentArea = this.areas[this.areas.length - 1];
  if (this.areas.length > 0 && !currentArea.closed) {
    const points = currentArea.points;
    this.removePoints(points);
    this.removeSnapIcon(false);

    if (this.measurementAreaEndingLine) {
      this.removeObjectFromScene(this.measurementAreaEndingLine);
      this.measurementAreaEndingLine = null;
    }
    this.removeDashedLine();

    if (currentArea.lines) {
      this.removeArrayFromScene(currentArea.lines.map((line) => line.line));
      currentArea.lines
        .map((line) => line.midPoint)
        .forEach((point) => {
          this.removeObjectWithChildrenFromScene(point);
        });
    }

    this.areas = this.areas.slice(0, this.areas.length - 1);
  }
};

export const stickMousePointerToDot = function (event) {
  // disable point placing when clicking outside the model
  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);

  if (
    this.areas.length === 0 ||
    this.areas[this.areas.length - 1].points.length === 0 ||
    this.areas[this.areas.length - 1].closed
  )
    return;

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) return;
  let o = intersects[0];
  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const currentArea = this.areas[this.areas.length - 1];

  const firstDot = currentArea?.points[0];
  const secondDot = currentArea?.points[1];
  const thirdDot = currentArea?.points[2];
  const lastDot = currentArea?.points[currentArea.points.length - 1];

  const distance = firstDot.position.distanceTo(pIntersect);
  const threshold = 0.25;

  if (firstDot && secondDot && thirdDot) {
    if (distance <= threshold) {
      this.selectedPoint = firstDot;
      this.renderer.domElement.style.cursor = `none`;
      this.areas[this.areas.length - 1].closeArea = true;
      this.dashedMeasurementLine.material.color.setHex(MOUNTING_SURFACE_COLOR);
      this.showSnapIcon();

      if (this.measurementAreaEndingLine)
        this.measurementAreaEndingLine.visible = false;
      this.inMagenticField = true;

      const firstPoint = new THREE.Vector3();
      const secondPoint = new THREE.Vector3();
      firstDot.getWorldPosition(firstPoint);
      lastDot.getWorldPosition(secondPoint);
      let points = [firstPoint, secondPoint];
      this.updateLinePosition(this.dashedMeasurementLine, points);
    } else {
      if (this.detectAreaIntersection()) {
        this.renderer.domElement.style.cursor = `pointer`;
      } else {
        this.changeCursorToCrosshair();
      }
      this.areas[this.areas.length - 1].closeArea = false;
      if (this.selectedPoint) {
        this.dashedMeasurementLine.material.color.setHex(SOLAR_POINT_COLOR);
        if (this.measurementAreaEndingLine)
          this.measurementAreaEndingLine.visible = true;
        this.inMagenticField = false;
        this.hideSnapIcon();
        this.selectedPoint = null;
      }
    }
  } else {
    if (this.detectAreaIntersection()) {
      this.renderer.domElement.style.cursor = `pointer`;
    } else {
      this.changeCursorToCrosshair();
    }
    if (this.selectedPoint) {
      this.dashedMeasurementLine.material.color.setHex(SOLAR_POINT_COLOR);
      if (this.measurementAreaEndingLine)
        this.measurementAreaEndingLine.visible = true;
      this.inMagenticField = false;
      this.selectedPoint = null;
      this.hideSnapIcon();
    }
  }
};

export const addPoint = function (event) {
  if (this.restrictedAreaMode) return;

  event.preventDefault();

  if (this.disableClick(event)) return;

  // Check if this is the first point
  const isFirstPoint =
    this.areas.length === 0 ||
    (this.areas.length > 0 && this.areas[this.areas.length - 1].closed);

  if (isFirstPoint) {
    this.colapseComponentsFrom("solarComponent");
  }

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) return;

  let shouldCloseArea = false;

  // check for closed area
  if (
    this.areas.length > 0 &&
    this.areas[this.areas.length - 1].points.length > 2 &&
    !this.areas[this.areas.length - 1].closed
  ) {
    let pointIntersects = this.raycaster.intersectObject(
      this.areas[this.areas.length - 1].points[0]
    );
    if (
      pointIntersects.length > 0 ||
      this.areas[this.areas.length - 1].closeArea
    ) {
      shouldCloseArea = true;
    }
  }

  // check for duplicate points
  if (
    this.areas.length > 0 &&
    this.areas[this.areas.length - 1].points.length > 0 &&
    !shouldCloseArea
  ) {
    let pointIntersects = this.raycaster.intersectObjects(
      this.areas
        .filter((area) => area.closed && area.show !== false)
        .map((area) => area.pointInstancedMesh)
    );
    if (pointIntersects.length > 0) {
      if (this.areas[this.areas.length - 1].closed) this.selectArea(event);
      return;
    }
  }

  if (
    this.areas.length > 0 &&
    this.areas[this.areas.length - 1].points &&
    this.areas[this.areas.length - 1].points.length > 0 &&
    this.areas[this.areas.length - 1].show !== false &&
    !shouldCloseArea
  ) {
    let points = this.areas[this.areas.length - 1].points.filter(
      (point) => point.isMesh
    );

    let pointIntersects = this.raycaster.intersectObjects(points);
    if (pointIntersects && pointIntersects.length > 0) {
      return;
    }
  }

  let solidLine = null;
  let midPoint = null;
  let oldTempLine = null;
  let oldFirstPoint = null;

  // replace dashed line with solid line
  if (this.areas.length > 0 && !this.areas[this.areas.length - 1].closed) {
    const lastArea = this.areas[this.areas.length - 1];
    oldFirstPoint = lastArea.firstPoint;
    if (lastArea.tempLine) {
      oldTempLine = lastArea.tempLine;
      const solidLinePoints = [
        lastArea.firstPoint.position,
        this.checkForClosedArea(lastArea)
          ? lastArea.points[0].position
          : lastArea.tempPoint.position,
      ];
      solidLine = this.createReactiveThickLine(
        solidLinePoints,
        4.0,
        false,
        false,
        SOLAR_POINT_COLOR
      );

      this.removeDashedLine();

      this.scene.add(solidLine);

      midPoint = this.createReactiveMidPoint(
        lastArea.firstPoint,
        this.checkForClosedArea(lastArea)
          ? lastArea.points[0]
          : lastArea.tempPoint,
        SOLAR_POINT_COLOR
      );
      this.scene.add(midPoint);

      lastArea.tempLine = null;
      if (lastArea.lines) {
        lastArea.lines.push({
          line: solidLine,
          firstPoint: lastArea.firstPoint,
          secondPoint: this.checkForClosedArea(lastArea)
            ? lastArea.points[0]
            : lastArea.tempPoint,
          midPoint: midPoint,
        });
      } else {
        lastArea.lines = [
          {
            line: solidLine,
            firstPoint: lastArea.firstPoint,
            secondPoint: this.checkForClosedArea(lastArea)
              ? lastArea.points[0]
              : lastArea.tempPoint,
            midPoint: midPoint,
          },
        ];
      }
    }
  }

  // check for closed area
  if (shouldCloseArea) {
    this.drawPlane(this.areas[this.areas.length - 1].points);
    return;
  }

  if (
    this.detectAreaIntersection() &&
    this.areas[this.areas.length - 1].closed
  ) {
    if (this.isAreaInFrontOfModel()) {
      this.selectArea(event);
      return;
    }
  }

  // disable marker adding/connecting when drag is on
  if (this.dragOn) return;

  let o = intersects[0];

  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const dot = this.createReactivePoint(
    pIntersect,
    SOLAR_POINT_COLOR,
    this.isFirstPoint
  );

  const tempPoint = this.createNonReactiveAreaPoint(
    pIntersect,
    SOLAR_POINT_COLOR
  );

  if (this.areas.length > 0 && !this.areas[this.areas.length - 1].closed) {
    this.areas[this.areas.length - 1].points.push(dot);
  } else {
    this.areas.push({ points: [dot], closed: false });
  }

  let area = this.areas[this.areas.length - 1];

  area.firstPoint = dot;
  area.tempPoint = tempPoint;

  if (area.lines && area.lines[area.lines.length - 1].firstPoint) {
    area.lines[area.lines.length - 1].secondPoint = dot;
  }
  this.camera.lookAt(area.firstPoint.position);

  const firstPoint = new THREE.Vector3();
  const secondPoint = new THREE.Vector3();
  area.firstPoint.getWorldPosition(firstPoint);
  area.tempPoint.getWorldPosition(secondPoint);
  let points = [firstPoint, secondPoint];

  let newLine = this.createReactiveThickLine(
    points,
    4.0,
    true,
    false,
    SOLAR_POINT_COLOR
  );
  this.dashedMeasurementLine = newLine;
  area.tempLine = newLine;
  this.scene.add(dot);

  if (area.points.length > 1)
    // add point undo callback function
    this.undoStack.push({
      action: "ADD_POINT",
      area: area,
      point: dot,
      line: solidLine,
      midPoint: midPoint,
      oldTempLine: oldTempLine,
      oldFirstPoint: oldFirstPoint,
      newTempLine: newLine,
    });
  this.resetRedoStack();
};

export const updatePreliminaryPointPositionForArea = function (event) {
  if (this.shouldThrottle()) return;

  let intersects = [];
  if (
    this.areas.length > 0 &&
    !this.areas[this.areas.length - 1].closed &&
    !this.inMagenticField
  ) {
    this.setMousePosition(event);

    intersects = this.raycaster.intersectObject(this.modelObject.children[0]);

    if (intersects.length < 1) return;
    let o = intersects[0];
    let pIntersect = o.point.clone();
    this.scene.worldToLocal(pIntersect);
    const area = this.areas[this.areas.length - 1];
    let marker = area.tempPoint;
    marker.position.x = pIntersect.x;
    marker.position.y = pIntersect.y;
    marker.position.z = pIntersect.z;
    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    area.firstPoint.getWorldPosition(firstPoint);
    area.tempPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];

    if (this.dashedMeasurementLine) {
      this.updateLinePosition(this.dashedMeasurementLine, points);
    }

    const line = this.dashedMeasurementLine;
    const lineObject = this.scene.getObjectById(line.id);
    if (!lineObject) this.scene.add(toRaw(line));

    const numPoints = area.points.length;
    if (numPoints > 2) {
      const areaFirstDot = new THREE.Vector3();
      area.points[0].getWorldPosition(areaFirstDot);
      const endingPoints = [areaFirstDot, secondPoint];

      if (this.measurementAreaEndingLine) {
        this.updateLinePosition(this.measurementAreaEndingLine, endingPoints);
      } else {
        let newDottedLine = this.createReactiveThickLine(
          endingPoints,
          4.0,
          true,
          true,
          SOLAR_POINT_COLOR
        );
        this.measurementAreaEndingLine = newDottedLine;
        this.scene.add(newDottedLine);
      }
    }
  }
  return intersects;
};

export const selectArea = function (event) {
  event.preventDefault();

  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);
  this.hideMeasurementDetails(this.measurements);
  const solarObjects = [];
  const filteredAreas = this.areas.filter(
    (area) => area.plane && (area.show === true || area.show === undefined)
  );
  for (let area of filteredAreas) {
    solarObjects.push(area.plane);
    solarObjects.push(area.pointInstancedMesh);
  }
  let intersects = this.raycaster.intersectObjects(solarObjects);
  if (intersects.length < 1) return;

  let areaToExpand = intersects[0].object;
  let clickedArea = this.areas.find(
    (area) => area.plane && area.plane.id === areaToExpand.id
  );

  if (!clickedArea) {
    clickedArea = this.areas.find(
      (area) => area.pointInstancedMesh.id === areaToExpand.id
    );
  }
  if (this.selectedArea !== clickedArea) {
    this.selectAreaOnSidebar(clickedArea);
  }

  if (this.existingAreasIds.includes(clickedArea.id) && this.anonymousUser) {
    return;
  }
  this.previousSolarArea = this.selectedArea;

  if (this.selectedArea && this.selectedArea.plane.id === clickedArea.plane.id)
    return;

  const areaAlreadySelected = this.selectedArea ? true : false;

  clickedArea.expanded = true;
  this.setLinesColorForArea(clickedArea);
  this.setPointsColorForArea(clickedArea);
  this.setMidPointsColorForArea(clickedArea);
  if (clickedArea.innerPlane) clickedArea.innerPlane.material.opacity = 0.25;

  this.areas
    .filter((area) => area.plane.id !== clickedArea.plane.id)
    .forEach((area) => {
      clickedArea.expanded = false;
      if (area.innerPlane) area.innerPlane.material.opacity = 0;
      if (area.moveGridLabel) area.moveGridLabel.visible = false;
      if (area.rotateGridLabel) area.rotateGridLabel.visible = false;
      this.setLinesColorForArea(area, SOLAR_POINT_COLOR);
      this.setPointsColorForArea(area, SOLAR_POINT_COLOR);
      this.setMidPointsColorForArea(area, SOLAR_POINT_COLOR);
      this.hideRestrictedAreaDetails(area);
    });

  if (areaAlreadySelected) this.disablePointDragModeForRestrictedAreas();

  this.selectedArea = clickedArea;

  if (!areaAlreadySelected) {
    document.removeEventListener("click", this.addPoint, false);
    window.removeEventListener("mousemove", this.stickMousePointerToDot);

    document.removeEventListener(
      "mousemove",
      this.detectModelIntersection,
      false
    );

    document.removeEventListener("keydown", this.removeLastPoint, false);
    document.addEventListener(
      "mousemove",
      this.detectSolarAreaPointsIntersection,
      false
    );
    document.addEventListener("mousemove", this.detectIndividualPanels, false);
    document.addEventListener(
      "mousedown",
      this.dragIndividualPanelStart,
      false
    );

    document.addEventListener("click", this.selectSolarArea, false);

    window.addEventListener("wheel", this.controlSpacingBetweenLabels, false);
  }

  this.showRestrictedAreaDetails(this.selectedArea);

  this.enablePointDragMode();
  this.enablePointDragModeForRestrictedAreas();

  this.checkPanelNumberAndVisibility(this.selectedArea);
  this.controlSpacingBetweenLabels();
};

export const selectAreaWithoutClick = function (area) {
  area.expanded = true;
  this.setLinesColorForArea(area);
  this.setPointsColorForArea(area);
  this.setMidPointsColorForArea(area);
  this.checkPanelNumberAndVisibility(area);
  if (area.innerPlane) area.innerPlane.material.opacity = 0.25;

  this.selectedArea = area;

  document.removeEventListener("click", this.addPoint, false);
  window.removeEventListener("mousemove", this.stickMousePointerToDot);

  document.removeEventListener(
    "mousemove",
    this.detectModelIntersection,
    false
  );

  document.removeEventListener("keydown", this.removeLastPoint, false);

  document.addEventListener(
    "mousemove",
    this.detectSolarAreaPointsIntersection,
    false
  );
  document.addEventListener("mousemove", this.detectIndividualPanels, false);
  document.addEventListener("mousedown", this.dragIndividualPanelStart, false);
  document.addEventListener("click", this.selectSolarArea, false);

  window.addEventListener("wheel", this.controlSpacingBetweenLabels, false);

  this.showRestrictedAreaDetails(this.selectedArea);

  this.enablePointDragMode();
  this.enablePointDragModeForRestrictedAreas();
};

export const selectSolarArea = function (event) {
  event.preventDefault();

  if (this.disableClick(event)) return;

  if (this.dragOn) return;

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) this.unselectSolarArea();
  if (this.detectAreaIntersection()) {
    if (this.checkIndividualPanelClicked(event)) return;
    this.selectArea(event);
  } else {
    this.unselectSolarArea();
  }
};

export const deselectSolarArea = function () {
  if (this.selectedArea) {
    this.hideRestrictedAreaDetails(this.selectedArea);
    this.selectedArea.expanded = false;
    this.setLinesColorForArea(this.selectedArea, SOLAR_POINT_COLOR);
    this.setPointsColorForArea(this.selectedArea, SOLAR_POINT_COLOR);
    this.setMidPointsColorForArea(this.selectedArea, SOLAR_POINT_COLOR);
    if (this.selectedArea.innerPlane)
      this.selectedArea.innerPlane.material.opacity = 0.0;
    if (this.selectedArea.moveGridLabel)
      this.selectedArea.moveGridLabel.visible = false;
    if (this.selectedArea.rotateGridLabel)
      this.selectedArea.rotateGridLabel.visible = false;

    this.selectedArea = null;
  }
};

export const unselectSolarArea = function (areaToDeselect = null) {
  if (areaToDeselect && areaToDeselect.id !== this.selectedArea?.id) {
    return;
  }
  this.unselectSolarAreaOnSidebar();
  if (this.active !== 2) {
    this.disablePointDragMode();
  }
  this.disablePointDragModeForRestrictedAreas();

  if (this.active === 3) {
    this.disableIndividualPlacementMode();
  }

  this.hideAllIndividualPanelsTrashIcons();
  this.deselectSolarArea();

  document.removeEventListener("click", this.selectSolarArea, false);
  document.removeEventListener(
    "mousemove",
    this.detectSolarAreaPointsIntersection,
    false
  );
  document.removeEventListener("mousemove", this.detectIndividualPanels, false);
  document.removeEventListener(
    "mousedown",
    this.dragIndividualPanelStart,
    false
  );
  document.removeEventListener("mousemove", this.dragIndividualPanel, false);
  document.removeEventListener("mouseup", this.dragIndividualPanelEnd, false);

  window.removeEventListener("mousedown", this.onDragSolarGroupStart);
  window.removeEventListener("mousemove", this.onDragSolarGroup);
  window.removeEventListener("mouseup", this.onDragSolarGroupEnd);

  document.addEventListener("click", this.addPoint, false);
  window.addEventListener("mousemove", this.stickMousePointerToDot);

  document.addEventListener("mousemove", this.detectModelIntersection, false);

  document.addEventListener("keydown", this.removeLastPoint, false);

  window.removeEventListener("wheel", this.controlSpacingBetweenLabels, false);
};

export const holdSolarArea = function (area) {
  if (area) {
    area.innerPlane.material.opacity = 0.0;
    this.disableDefaultNavigation();
    document.removeEventListener("click", this.selectSolarArea, false);
    document.removeEventListener(
      "mousemove",
      this.detectSolarAreaPointsIntersection,
      false
    );
    document.removeEventListener(
      "mousemove",
      this.detectIndividualPanels,
      false
    );
    document.removeEventListener(
      "mousedown",
      this.dragIndividualPanelStart,
      false
    );

    document.removeEventListener("click", this.addPoint, false);
    window.removeEventListener("mousemove", this.stickMousePointerToDot);

    document.removeEventListener(
      "mousemove",
      this.detectModelIntersection,
      false
    );

    document.removeEventListener("keydown", this.removeLastPoint, false);
  }
};

export const unHoldSolarArea = function (area) {
  if (area) {
    if (this.selectedArea) {
      this.deselectSolarArea();
    }

    this.selectedArea = area;
    setTimeout(() => {
      this.selectAreaWithoutClick(area);
    }, 0);
  }
};

export const setLinesColorForArea = function (
  area,
  color = SELECTED_LINE_COLOR
) {
  area.combinedLine.material.color.setHex(color);
};

export const setPointsColorForArea = function (
  area,
  color = SELECTED_LINE_COLOR
) {
  if (area && area.pointInstancedMesh) {
    area.pointInstancedMesh.material.uniforms.innerColor.value.set(color);
    area.pointInstancedMesh.material.uniforms.innerColor.needsUpdate = true;
  }
};

export const setMidPointsColorForArea = function (
  area,
  color = SELECTED_LINE_COLOR
) {
  if (area && area.midpointInstancedMesh) {
    area.midpointInstancedMesh.material.uniforms.outerColor.value.set(color);
    area.midpointInstancedMesh.material.uniforms.outerColor.needsUpdate = true;
  }
};

export const onDragSolarGroupStart = function (event) {
  this.dragOn = true;

  const areaPlane = this.selectedArea.trianglePlane;

  this.setLabelOpacity(this.selectedArea.moveGridLabel, 1.0);
  this.setLabelOpacity(this.selectedArea.rotateGridLabel, 0.0);

  this.selectedArea.instancedMesh.material.opacity = 0.75;

  this.disablePointerEventsOnLabel(this.selectedArea.rotateGridLabel);

  const originalPosition = new THREE.Vector3(
    this.selectedArea.moveGridLabel.position.x,
    this.selectedArea.moveGridLabel.position.y,
    this.selectedArea.moveGridLabel.position.z
  );

  this.setMousePosition(event);
  const initialIntersectionPoint = new THREE.Vector3();
  this.raycaster.ray.intersectPlane(
    this.selectedArea.infinitePlane,
    initialIntersectionPoint
  );

  const projectedOriginalPosition = new THREE.Vector3();
  areaPlane.projectPoint(originalPosition, projectedOriginalPosition);

  this.selectedArea.moveGridLabel.originalPosition = {
    x: projectedOriginalPosition.x,
    y: projectedOriginalPosition.y,
    z: projectedOriginalPosition.z,
  };

  this.selectedArea.moveGridLabel.clickOffset = new THREE.Vector3().subVectors(
    projectedOriginalPosition,
    initialIntersectionPoint
  );

  this.checkPanelNumberAndVisibility(this.selectedArea);

  document.addEventListener("mousemove", this.onDragSolarGroup);
  document.addEventListener("mouseup", this.onDragSolarGroupEnd);
};

export const onDragSolarGroup = function (event) {
  if (!this.dragOn) return;

  this.setMousePosition(event);

  const intersectionPoint = new THREE.Vector3();
  const hasIntersection = this.raycaster.ray.intersectPlane(
    this.selectedArea.infinitePlane,
    intersectionPoint
  );

  if (!hasIntersection) {
    this.dragOn = false;
    return;
  }

  const areaPlane = this.selectedArea.trianglePlane;

  const clickOffset = this.selectedArea.moveGridLabel.clickOffset;
  intersectionPoint.add(clickOffset);

  const projectedPoint = new THREE.Vector3();
  areaPlane.projectPoint(intersectionPoint, projectedPoint);

  const translationVector = new THREE.Vector3();
  translationVector.subVectors(
    projectedPoint,
    new THREE.Vector3(
      this.selectedArea.moveGridLabel.originalPosition.x,
      this.selectedArea.moveGridLabel.originalPosition.y,
      this.selectedArea.moveGridLabel.originalPosition.z
    )
  );

  const { instancedMesh } = this.selectedArea;
  const tempPosition = new THREE.Vector3();
  const transformationMatrix = new THREE.Matrix4();

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);

    transformationMatrix.decompose(
      tempPosition,
      new THREE.Quaternion(),
      new THREE.Vector3()
    );

    tempPosition.add(translationVector);
    transformationMatrix.setPosition(tempPosition);

    instancedMesh.setMatrixAt(i, transformationMatrix.clone());
  }

  instancedMesh.seed.add(translationVector);
  instancedMesh.instanceMatrix.needsUpdate = true;

  this.selectedArea.moveGridLabel.position.copy(projectedPoint);

  this.selectedArea.moveGridLabel.originalPosition = {
    x: projectedPoint.x,
    y: projectedPoint.y,
    z: projectedPoint.z,
  };
};

export const onDragSolarGroupEnd = function (event) {
  this.dragOn = false;
  this.selectAreaWithoutClick(this.selectedArea);
  this.checkPanelsInSolarArea(this.selectedArea);

  this.setTranslationVectorForSolarGroup();
  this.checkAndPopulateEmptyArea(this.selectedArea);

  this.selectedArea.panelsCenter = this.calculatePanelsCenter(
    this.selectedArea
  );
  this.updateSolarGroupLabelsPosition({
    centerPoint: this.selectedArea.panelsCenter,
    moveLabel: this.selectedArea.moveGridLabel,
    rotateLabel: this.selectedArea.rotateGridLabel,
    horizontalVector: this.selectedArea.horizontalVector,
  });

  document.removeEventListener("mousemove", this.onDragSolarGroup);
  document.removeEventListener("mouseup", this.onDragSolarGroupEnd);
};

export const setTranslationVectorForSolarGroup = function () {
  const finalPosition = this.selectedArea.instancedMesh.seed;

  const offset = this.selectedArea.normal
    .clone()
    .multiplyScalar(this.selectedArea.offset / 100);

  const finalPositionNoOffset = finalPosition.clone();
  finalPositionNoOffset.add(offset);

  this.selectedArea.startPosition = finalPositionNoOffset;

  const moveGridLabel = this.selectedArea.moveGridLabel;
  const rotateGridLabel = this.selectedArea.rotateGridLabel;

  this.setLabelOpacity(moveGridLabel, 0.7);
  this.setLabelOpacity(rotateGridLabel, 0.7);
  this.enablePointerEventsOnLabel(this.selectedArea.rotateGridLabel);

  this.selectedArea.instancedMesh.material.opacity = 0.5;

  this.createUpdateSolarGroup(this.selectedArea);
};

export const checkAndPopulateEmptyArea = function (area) {
  if (
    area.instancedMesh.visibilityArray.some((visibility) => visibility === true)
  )
    return;

  this.removeSolarGroupPanels(area);
  this.populateArea(area);
};

export const setLabelOpacity = function (label, opacity) {
  label.element.style.opacity = opacity;
};

export const enablePointerEventsOnLabel = function (label) {
  label.element.style.pointerEvents = "all";
};

export const disablePointerEventsOnLabel = function (label) {
  label.element.style.pointerEvents = "none";
};

export const onRotateSolarGroupStart = function (event) {
  this.dragOn = true;

  this.initialMouseX = event.clientX;

  if (!this.selectedArea.currentRotation) {
    this.selectedArea.currentRotation = 0;
  }

  this.selectedArea.panelsCenter = this.calculatePanelsCenter(
    this.selectedArea
  );

  this.setLabelOpacity(this.selectedArea.moveGridLabel, 0.0);
  this.setLabelOpacity(this.selectedArea.rotateGridLabel, 1.0);

  this.disablePointerEventsOnLabel(this.selectedArea.moveGridLabel);

  this.selectedArea.instancedMesh.material.opacity = 0.75;
  if (this.selectedArea.verticalPanelInstancedMesh)
    this.selectedArea.verticalPanelInstancedMesh.material.opacity = 0.75;
  if (this.selectedArea.horizontalPanelInstancedMesh)
    this.selectedArea.horizontalPanelInstancedMesh.material.opacity = 0.75;

  document.addEventListener("mousemove", this.onRotateSolarGroup);
  document.addEventListener("mouseup", this.onRotateSolarGroupEnd);
};

export const onRotateSolarGroup = function (event) {
  if (!this.dragOn) return;

  const deltaX = event.clientX - this.initialMouseX;
  const rotationAngle = deltaX * this.rotationSpeed;

  this.initialMouseX = event.clientX;

  const accumulatedRotation = this.selectedArea.currentRotation + rotationAngle;

  if (!this.isAngleInRange(accumulatedRotation)) return;
  this.selectedArea.currentRotation = accumulatedRotation;

  const {
    normal,
    instancedMesh,
    panelsCenter,
    verticalPanelInstancedMesh,
    horizontalPanelInstancedMesh,
  } = this.selectedArea;

  this.applyRotationToInstancedMesh(
    instancedMesh,
    normal,
    panelsCenter,
    rotationAngle
  );

  if (verticalPanelInstancedMesh)
    this.applyRotationToInstancedMesh(
      verticalPanelInstancedMesh,
      normal,
      panelsCenter,
      rotationAngle
    );

  if (horizontalPanelInstancedMesh)
    this.applyRotationToInstancedMesh(
      horizontalPanelInstancedMesh,
      normal,
      panelsCenter,
      rotationAngle
    );
};

export const onRotateSolarGroupEnd = function () {
  this.dragOn = false;

  this.validateIndividualPanels(this.selectedArea);

  for (let i = 0; i < this.selectedArea.restrictedAreas.length; i++) {
    this.checkIndividualPanelIntersectionWithRestrictedArea(
      this.selectedArea,
      i
    );
  }

  this.checkPanelsInSolarArea(this.selectedArea);

  this.setLabelOpacity(this.selectedArea.moveGridLabel, 0.7);
  this.setLabelOpacity(this.selectedArea.rotateGridLabel, 0.7);

  this.enablePointerEventsOnLabel(this.selectedArea.moveGridLabel);

  this.selectedArea.instancedMesh.material.opacity = 0.5;
  if (this.selectedArea.verticalPanelInstancedMesh)
    this.selectedArea.verticalPanelInstancedMesh.material.opacity = 0.5;
  if (this.selectedArea.horizontalPanelInstancedMesh)
    this.selectedArea.horizontalPanelInstancedMesh.material.opacity = 0.5;

  this.checkPanelNumberAndVisibility(this.selectedArea);

  document.removeEventListener("mousemove", this.onRotateSolarGroup);
  document.removeEventListener("mouseup", this.onRotateSolarGroupEnd);

  this.createUpdateSolarGroup(this.selectedArea);
};

export const applyRotationToInstancedMesh = function (
  instancedMesh,
  normal,
  panelsCenter,
  rotationAngle
) {
  const {
    transformationPosition,
    transformationQuaternion,
    transformationScale,
    transformationMatrix,
  } = this.createTransformationComponents();

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);
    transformationMatrix.decompose(
      transformationPosition,
      transformationQuaternion,
      transformationScale
    );

    transformationPosition.sub(panelsCenter);

    const rotationMatrix = new THREE.Matrix4().makeRotationAxis(
      normal,
      rotationAngle
    );
    transformationPosition.applyMatrix4(rotationMatrix);

    const newQuaternion = new THREE.Quaternion().setFromRotationMatrix(
      rotationMatrix
    );

    transformationQuaternion.premultiply(newQuaternion);

    transformationPosition.add(panelsCenter);

    transformationMatrix.compose(
      transformationPosition,
      transformationQuaternion,
      transformationScale
    );
    instancedMesh.setMatrixAt(i, transformationMatrix);
  }

  instancedMesh.instanceMatrix.needsUpdate = true;
};

export const updatePanelVisibility = function (selectedArea) {
  const { normal, instancedMesh, offset, testPanel, simulatedCamera, ground } =
    selectedArea;

  const transformationMatrix = new THREE.Matrix4();
  const tempPosition = new THREE.Vector3();
  const tempQuaternion = new THREE.Quaternion();
  const tempScale = new THREE.Vector3(1, 1, 1);

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    if (!instancedMesh.visibilityArray[i]) continue;

    instancedMesh.getMatrixAt(i, transformationMatrix);
    transformationMatrix.decompose(tempPosition, tempQuaternion, tempScale);

    const tempPositionWithoutOffset = tempPosition.clone();
    const meterOffset = normal.clone().multiplyScalar(offset / 100);
    tempPositionWithoutOffset.add(meterOffset);

    if (
      !this.panelInsideArea(
        tempPositionWithoutOffset,
        tempQuaternion,
        testPanel,
        ground,
        normal,
        simulatedCamera,
        selectedArea.innerPlane
      )
    ) {
      instancedMesh.setVisibilityAt(i, false);
    }
  }

  instancedMesh.instanceMatrix.needsUpdate = true;
};

export const hideAreaPoints = function (area) {
  area.points.forEach((point) => (point.visible = false));
  area.lines.forEach((line) => {
    line.midPoint.visible = false;
    line.line.visible = false;
  });
  document.removeEventListener("click", this.addPoint, false);
};

export const panelInsideArea = function (
  position,
  rotation,
  testPanel,
  ground,
  normal,
  simulatedCamera,
  innerPlane
) {
  const { panelVertices, panelEdges } = this.createPanelAt({
    panel: testPanel,
    pos: position,
    rotation,
    ground,
    normal,
  });

  if (
    !this.isRectangleWithinPoints(
      panelVertices,
      panelEdges,
      innerPlane.points,
      innerPlane.edges,
      simulatedCamera
    )
  ) {
    return false;
  }
  return true;
};

export const createPlaneForSolarArea = function (points) {
  let triangle = new THREE.Triangle(points[0], points[1], points[2]);

  let plane = new THREE.Plane();
  triangle.getPlane(plane);

  return plane;
};

export const projectPointsOnPlane = function (points, plane) {
  const vectorPoints = points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  const projectedPoints = [];
  for (let i = 0; i < vectorPoints.length; i++) {
    const projectedPoint = new THREE.Vector3();
    plane.projectPoint(vectorPoints[i], projectedPoint);
    projectedPoints.push(projectedPoint);

    points[i].position.x = projectedPoint.x;
    points[i].position.y = projectedPoint.y;
    points[i].position.z = projectedPoint.z;
  }

  const projectedPointsAsArray = projectedPoints.map((point) => [
    point.x,
    point.y,
    point.z,
  ]);

  const flatProjectedPoints = [].concat(...projectedPointsAsArray);

  return flatProjectedPoints;
};

export const projectLinesOnPlane = function (lines, plane) {
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    line.firstPoint.getWorldPosition(firstPoint);
    line.secondPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];

    this.updateLinePosition(line.line, points);
    if (line.midPoint) {
      const newPosition = new THREE.Vector3().lerpVectors(
        firstPoint,
        secondPoint,
        0.5
      );

      line.midPoint.position.copy(newPosition);
    }
  }
};

export const drawPlane = async function (points, createArea = true) {
  this.resetUndoStack();
  this.resetRedoStack();
  this.removeSnapIcon();

  this.removeDashedLine();

  if (this.measurementAreaEndingLine)
    this.removeObjectFromScene(this.measurementAreaEndingLine);

  this.measurementAreaEndingLine = null;

  const vectorPoints = points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  const areaPlane = this.createPlaneForSolarArea(vectorPoints);

  const projectedPoints = this.projectPointsOnPlane(points, areaPlane);

  this.projectLinesOnPlane(this.areas[this.areas.length - 1].lines, areaPlane);
  const geometry = new THREE.BufferGeometry();

  const vertices = new Float32Array(projectedPoints);

  const triangleIndices = this.getTriangleIndices(
    points,
    this.getAxisDifferences(points.map((point) => point.position))
  );
  const indices = [].concat(...triangleIndices);

  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex(new THREE.Uint16BufferAttribute(indices, 1));

  const material = new THREE.MeshBasicMaterial({
    color: MARGIN_COLOR,
    side: THREE.DoubleSide,
    stencilWrite: true,
    stencilFunc: THREE.NeverStencilFunc,
    stencilRef: this.stencilCount,
    stencilZPass: THREE.ReplaceStencilOp,
    transparent: true,
    opacity: 0.0,
  });

  const plane = new THREE.Mesh(geometry, material);

  plane.material.depthTest = false;
  plane.renderOrder = RENDERING_ORDER.SOLAR_PLANE;

  const instancedMesh = this.replacePointsWithInstancedMesh(
    points,
    SOLAR_POINT_COLOR
  );
  this.scene.add(instancedMesh);

  const midpointInstancedMesh = this.replacePointsWithInstancedMesh(
    this.areas[this.areas.length - 1].lines.map((line) => line.midPoint),
    SOLAR_POINT_COLOR,
    false,
    true
  );
  this.scene.add(midpointInstancedMesh);

  this.combineAreaLines(this.areas[this.areas.length - 1], SOLAR_POINT_COLOR);

  if (createArea) {
    if (this.solarDefaultValues) {
      this.areas[this.areas.length - 1] = {
        ...this.areas[this.areas.length - 1],
        ...this.solarDefaultValues,
      };
    } else {
      this.useDefaultSolarValues(this.areas[this.areas.length - 1]);
    }
    this.areas[this.areas.length - 1].type = "SOLAR_GROUP";
  }
  this.areas[this.areas.length - 1].plane = plane;
  this.areas[this.areas.length - 1].expanded = false;
  this.areas[this.areas.length - 1].transparencyLevel = 100;
  this.areas[this.areas.length - 1].panelType = null;
  this.areas[this.areas.length - 1].panels = [];
  this.areas[this.areas.length - 1].restrictedAreas =
    this.areas[this.areas.length - 1].restrictedAreas || [];
  this.areas[this.areas.length - 1].closed = true;
  this.areas[this.areas.length - 1].closeArea = false;
  this.areas[this.areas.length - 1].trianglePlane = areaPlane;
  this.areas[this.areas.length - 1].indices = indices;
  this.areas[this.areas.length - 1].stencilCount = this.stencilCount;
  this.areas[this.areas.length - 1].angle = this.calculateAreaAngle(
    geometry,
    material
  );
  this.areas[this.areas.length - 1].pointInstancedMesh = instancedMesh;
  this.areas[this.areas.length - 1].midpointInstancedMesh =
    midpointInstancedMesh;

  this.scene.add(plane);
  this.stencilCount++;

  if (createArea) {
    this.selectAreaWithoutClick(this.areas[this.areas.length - 1]);
    this.populateArea(this.selectedArea);
    this.checkPanelNumberAndVisibility(this.selectedArea);
    return;
  }
  if (this.areas[this.areas.length - 1].type === "SOLAR_GROUP")
    this.populateArea(this.areas[this.areas.length - 1], false);
};

export const createAreaObject = async function (points) {
  if (this.sample) return;
  const areaObject = {
    projectId: Number(this.projectId),
    position: points,
  };
  return await API.airteam3DViewer.createAreaObject(areaObject);
};

export const updateAreaObject = async function (id, points) {
  if (this.sample || this.anonymousUser) return;
  const areaObject = {
    id,
    projectId: Number(this.projectId),
    position: points,
  };
  return await API.airteam3DViewer.updateAreaObject(areaObject);
};

export const deleteAreaObject = async function (id) {
  if (this.sample) return;
  return await API.airteam3DViewer.deleteObject(id);
};

export const removeSolarGroup = async function (solarGroup) {
  this.removeObjectFromScene(solarGroup.plane);

  this.removeObjectFromScene(solarGroup.pointInstancedMesh);
  this.removeObjectFromScene(solarGroup.midpointInstancedMesh);
  this.removeObjectFromScene(solarGroup.combinedLine);

  if (solarGroup.firstPoint) this.removeObjectFromScene(solarGroup.firstPoint);
  if (solarGroup.tempPoint) this.removeObjectFromScene(solarGroup.tempPoint);

  if (solarGroup.verticalPanelInstancedMesh)
    this.removeObjectFromScene(solarGroup.verticalPanelInstancedMesh);
  if (solarGroup.horizontalPanelInstancedMesh)
    this.removeObjectFromScene(solarGroup.horizontalPanelInstancedMesh);
  if (solarGroup.firstPoint) this.removeObjectFromScene(solarGroup.firstPoint);

  this.removeObjectFromScene(solarGroup.simulatedCamera);
  this.removeObjectFromScene(solarGroup.testPanel);

  this.removeSolarGroupPanels(solarGroup);

  this.areas = this.areas.filter(
    (area) => !area.plane || area.plane.id !== solarGroup.plane.id
  );
  this.removeAllRestrictedAreas(solarGroup);

  const moveGridLabel = this.scene.getObjectById(solarGroup.moveGridLabel?.id);
  const rotateGridLabel = this.scene.getObjectById(
    solarGroup.rotateGridLabel?.id
  );
  if (moveGridLabel) this.removeObjectFromScene(moveGridLabel);
  if (rotateGridLabel) this.removeObjectFromScene(rotateGridLabel);

  return await this.deleteAreaObject(solarGroup.id);
};

export const removeSelectedArea = function (areaToRemove) {
  if (areaToRemove.type === "SOLAR_GROUP") {
    this.unselectSolarArea();
    return this.removeSolarGroup(areaToRemove);
  }

  this.removeObjectFromScene(areaToRemove.plane);

  this.removeObjectFromScene(areaToRemove.pointInstancedMesh);
  this.removeObjectFromScene(areaToRemove.midpointInstancedMesh);
  this.removeObjectFromScene(areaToRemove.combinedLine);

  for (let panel of areaToRemove.panels) {
    this.removeObjectFromScene(panel.plane);
  }

  this.areas = this.areas.filter(
    (area) => !area.plane || area.plane.id !== areaToRemove.plane.id
  );

  this.deleteAreaObject(areaToRemove.id);
};

export const dragStarted = function (e) {
  if (this.disableClick(e)) return;

  this.setMousePosition(e);
  const closedAreas = this.areas.filter(
    (area) => area.closed && area.show !== false
  );
  const points = closedAreas.map((area) => area.pointInstancedMesh);
  const midpoints = closedAreas.map((area) => area.midpointInstancedMesh);

  const pointIntersects = this.raycaster.intersectObjects(points, true);

  const midpointIntersects = this.raycaster.intersectObjects(midpoints, true);
  if (pointIntersects.length === 0 && midpointIntersects.length === 0) return;

  let isMidpoint = false;
  let intersectionPoint;

  if (pointIntersects.length > 0) {
    intersectionPoint = pointIntersects[0];
  } else {
    intersectionPoint = midpointIntersects[0];
    isMidpoint = true;
  }

  this.draggedAreaPoint = {
    area: this.areas.find((area) =>
      isMidpoint
        ? area.midpointInstancedMesh.id === intersectionPoint.object.id
        : area.pointInstancedMesh.id === intersectionPoint.object.id
    ),
    index: intersectionPoint.instanceId,
    isMidpoint,
  };

  const originalPosition = new THREE.Vector3();
  const transformationMatrix = new THREE.Matrix4();

  if (isMidpoint) {
    this.draggedAreaPoint.area.midpointInstancedMesh.getMatrixAt(
      this.draggedAreaPoint.index,
      transformationMatrix
    );
    this.draggedAreaPoint.area.midPointAdded = false;
  } else {
    this.draggedAreaPoint.area.pointInstancedMesh.getMatrixAt(
      this.draggedAreaPoint.index,
      transformationMatrix
    );
  }
  let area = this.areas[this.areas.length - 1];

  transformationMatrix.decompose(
    originalPosition,
    new THREE.Quaternion(),
    new THREE.Vector3()
  );

  this.draggedAreaPoint.lastPosition = {
    x: originalPosition.x,
    y: originalPosition.y,
    z: originalPosition.z,
  };

  this.draggedAreaPoint.originalPosition = {
    x: originalPosition.x,
    y: originalPosition.y,
    z: originalPosition.z,
  };

  if (this.selectedArea?.id !== this.draggedAreaPoint.area.id) {
    this.selectAreaOnSidebar(this.draggedAreaPoint.area);
  }
  if (this.selectedArea) {
    this.deselectSolarArea();
  }
  this.selectAreaWithoutClick(this.draggedAreaPoint.area);
  this.holdSolarArea(this.draggedAreaPoint.area);

  if (this.selectedArea.moveGridLabel) {
    this.selectedArea.moveGridLabel.visible = false;
    this.selectedArea.rotateGridLabel.visible = false;
  }

  document.addEventListener("mousemove", this.dragAreaPoint);
  document.addEventListener("mouseup", this.dragEnded);

  this.dragOn = true;
};

export const dragEnded = function () {
  const { index, prevPosition, area } = this.draggedAreaPoint;

  if (!this.draggedAreaPoint.mergedPoints) {
    const originalPosition = new THREE.Vector3();
    const transformationMatrix = new THREE.Matrix4();

    area.pointInstancedMesh.getMatrixAt(index, transformationMatrix);

    transformationMatrix.decompose(
      originalPosition,
      new THREE.Quaternion(),
      new THREE.Vector3()
    );

    const cameraPosition = this.camera.position.clone();
    const pointPosition = originalPosition.clone();
    const rayDirection = pointPosition.sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, rayDirection);

    const intersects = this.raycaster.intersectObject(
      this.modelObject.children[0]
    );

    if (intersects.length < 1 && prevPosition) {
      point.position.x = prevPosition.x;
      point.position.y = prevPosition.y;
      point.position.z = prevPosition.z;

      this.reDrawAreaFromPoint();
    }
  }

  this.resetUndoStack();
  this.resetRedoStack();

  this.replaceSolarGroupInnerPlane(area);
  this.validateIndividualPanels(area);

  if (area.instancedMesh) this.checkPanelsInSolarArea(area);

  const currentPlacementPanelsCount = area.instancedMesh
    ? area.instancedMesh.visibilityArray.filter((v) => v === true).length
    : 0;

  const newPlacementPanelsCount = this.simulatePopulateArea(area);

  if (newPlacementPanelsCount > currentPlacementPanelsCount) {
    this.removeSolarGroupPanels(area);
    this.populateArea(area, true, false, true);
  } else {
    this.createUpdateSolarGroup(area);
  }

  if (area.restrictedAreas) {
    area.restrictedAreas.forEach((restrictedArea) => {
      let outsideRA = [];

      const restrictedAreaPoints = restrictedArea.points.map(
        (point) =>
          new THREE.Vector3(
            point.position.x,
            point.position.y,
            point.position.z
          )
      );

      if (
        !this.isRectangleWithinPoints(
          restrictedAreaPoints,
          restrictedArea.edges,
          area.innerPlane.points,
          area.innerPlane.edges,
          area.simulatedCamera
        )
      ) {
        outsideRA.push(restrictedArea);
      }

      if (outsideRA.length > 0) {
        outsideRA.forEach((restrictedArea) => {
          this.removeRestrictedArea(restrictedArea);
        });

        if (area.instancedMesh) this.checkPanelsInSolarArea(area);
      }
    });
  }

  this.unHoldSolarArea(area);

  this.checkPanelNumberAndVisibility(this.selectedArea);

  document.removeEventListener("mousemove", this.dragAreaPoint);
  document.removeEventListener("mouseup", this.dragEnded);
  document.addEventListener("click", this.selectMeasurementArea, false);

  this.draggedAreaPoint = null;
  this.dragOn = false;
};

export const enablePointDragMode = function () {
  document.addEventListener("mousedown", this.dragStarted);
};

export const disablePointDragMode = function () {
  document.removeEventListener("mousedown", this.dragStarted);
  document.removeEventListener("mousemove", this.dragAreaPoint);
  document.removeEventListener("mouseup", this.dragEnded);
};

export const calculateAreaAngle = function (geometry, material) {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );

  const cube = new THREE.Mesh(geometry, material);

  scene.add(cube);
  camera.position.z = 5;

  const faceIndex = 0;

  const normal = new THREE.Vector3();
  const a = new THREE.Vector3();
  const b = new THREE.Vector3();
  const c = new THREE.Vector3();

  const positionAttribute = geometry.getAttribute("position");
  const indexAttribute = geometry.getIndex();

  a.fromBufferAttribute(positionAttribute, indexAttribute.array[faceIndex * 3]);
  b.fromBufferAttribute(
    positionAttribute,
    indexAttribute.array[faceIndex * 3 + 1]
  );
  c.fromBufferAttribute(
    positionAttribute,
    indexAttribute.array[faceIndex * 3 + 2]
  );

  normal.subVectors(c, b).cross(b.sub(a)).normalize();

  const referenceVector = new THREE.Vector3(0, 1, 0);

  const angle = normal.angleTo(referenceVector);

  const angleInRadians =
    normal.dot(referenceVector) < 0 ? Math.PI - angle : angle;

  const angleInDegrees = angleInRadians * (180 / Math.PI);

  const roundedAngleInDegrees = parseFloat(angleInDegrees.toFixed(2));

  return roundedAngleInDegrees;
};

export const removePoints = function (points) {
  for (let point of points) {
    const object = this.scene.getObjectById(point.id);
    this.scene.remove(object);
  }
};

export const detectAreaIntersection = function () {
  const solarObjects = [];
  const filteredAreas = this.areas.filter(
    (area) => area.plane && area.plane.visible
  );
  for (let area of filteredAreas) {
    solarObjects.push(area.plane);
    solarObjects.push(area.pointInstancedMesh);
  }

  let intersects = this.raycaster.intersectObjects(solarObjects);
  if (intersects.length > 0) return true;
  return false;
};

export const isAreaInFrontOfModel = function (areaDragged = false) {
  if (areaDragged) return true;

  const solarObjects = [this.modelObject.children[0]];
  const filteredAreas = this.areas.filter(
    (area) => area.plane && area.plane.visible
  );
  for (let area of filteredAreas) {
    solarObjects.push(area.plane);
    solarObjects.push(area.pointInstancedMesh);
  }

  let intersects = this.raycaster.intersectObjects(solarObjects);
  if (intersects.length > 1) {
    const model = intersects.find(
      (object) => object.object.uuid === this.modelObject.children[0].uuid
    );
    const area = intersects.find(
      (object) => object.object.uuid !== this.modelObject.children[0].uuid
    );

    if (!model || !area) return true;

    const modelDistance = model.distance;
    const areaDistance = area.distance;

    if (Math.abs(areaDistance - modelDistance) < 1) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

export const dragAreaPoint = function (e) {
  if (!this.dragOn) return;
  if (
    this.draggedAreaPoint.isMidpoint &&
    !this.draggedAreaPoint.area.midPointAdded
  ) {
    return this.dragAreaMidPoint(e);
  }
  this.setMousePosition(e);

  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  const area = this.draggedAreaPoint.area;
  const instancedMesh = area.pointInstancedMesh;
  const pointIndex = this.draggedAreaPoint.index;

  if (intersects.length > 0) {
    const intersectionPoint = new THREE.Vector3();
    this.raycaster.ray.intersectPlane(
      this.selectedArea.infinitePlane,
      intersectionPoint
    );

    const transformationMatrix = new THREE.Matrix4();
    const newPosition = new THREE.Vector3();

    instancedMesh.getMatrixAt(pointIndex, transformationMatrix);

    transformationMatrix.decompose(
      newPosition,
      new THREE.Quaternion(),
      new THREE.Vector3()
    );

    newPosition.set(
      intersectionPoint.x,
      intersectionPoint.y,
      intersectionPoint.z
    );

    transformationMatrix.setPosition(newPosition);
    instancedMesh.setMatrixAt(pointIndex, transformationMatrix);

    instancedMesh.instanceMatrix.needsUpdate = true;
    instancedMesh.computeBVH();
    area.midpointInstancedMesh.computeBVH();

    this.updatePositionOnObject(area.points[pointIndex], newPosition);
  }

  const pointsMerged = this.checkMergePoints(pointIndex, area, false);
  this.reDrawAreaFromPoint(pointsMerged);

  this.draggedAreaPoint.pointsMerged = pointsMerged;

  if (pointsMerged) {
    this.dragEnded(e);
  }
};

export const dragAreaMidPoint = function (e) {
  this.setMousePosition(e);

  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  const area = this.draggedAreaPoint.area;
  const instancedMesh = area.midpointInstancedMesh;
  const pointIndex = this.draggedAreaPoint.index;

  if (intersects.length > 0) {
    const transformationMatrix = new THREE.Matrix4();
    const newPosition = new THREE.Vector3();

    instancedMesh.getMatrixAt(pointIndex, transformationMatrix);

    transformationMatrix.decompose(
      newPosition,
      new THREE.Quaternion(),
      new THREE.Vector3()
    );

    newPosition.set(
      intersects[0].point.x,
      intersects[0].point.y,
      intersects[0].point.z
    );

    transformationMatrix.setPosition(newPosition);
    instancedMesh.setMatrixAt(pointIndex, transformationMatrix);

    instancedMesh.instanceMatrix.needsUpdate = true;
    instancedMesh.computeBVH();
    area.pointInstancedMesh.computeBVH();

    if (area.lines[pointIndex].midPoint.children.length > 0) {
      this.updatePositionOnObject(
        area.lines[pointIndex].midPoint.children[0],
        newPosition
      );
      this.updatePositionOnObject(
        area.lines[pointIndex].midPoint.children[1],
        newPosition
      );
    } else {
      this.updatePositionOnObject(area.lines[pointIndex].midPoint, newPosition);
    }
  }

  this.draggedAreaPoint.intersectionPoint = intersects[0].point;

  area.points.splice(pointIndex + 1, 0, {
    position: intersects[0].point,
  });

  area.midPointAdded = true;

  // replace points with instanced mesh
  const pointInstancedMesh = this.replacePointsWithInstancedMesh(
    area.points,
    SOLAR_POINT_COLOR,
    area.pointInstancedMesh
  );
  this.scene.add(pointInstancedMesh);
  area.pointInstancedMesh = pointInstancedMesh;

  // replace midpoints with instanced mesh
  for (let line of area.lines) {
    this.removeObjectFromScene(line.midPoint);
  }
  this.reCreateLinesFromPoints(area, false);

  const midpointInstancedMesh = this.replacePointsWithInstancedMesh(
    area.lines.map((line) => line.midPoint),
    SOLAR_POINT_COLOR,
    area.midpointInstancedMesh,
    true
  );
  this.scene.add(midpointInstancedMesh);
  area.midpointInstancedMesh = midpointInstancedMesh;

  this.updateClosedLinePosition({
    line: area.combinedLine,
    points: area.points,
    forceUpdate: true,
  });

  this.draggedAreaPoint.index = pointIndex + 1;
};

export const reDrawAreaFromPoint = function (mergedPoints = false) {
  let selectedArea = this.draggedAreaPoint.area;
  const pointIndex = this.draggedAreaPoint.index;
  if (!selectedArea) return;

  const projectedPoints = this.projectPointsOnPlane(
    selectedArea.points,
    selectedArea.trianglePlane
  );

  const vertices = new Float32Array(projectedPoints);

  const triangleIndices = this.getTriangleIndices(
    selectedArea.points,
    this.getAxisDifferences(selectedArea.points.map((point) => point.position))
  );
  const indices = [].concat(...triangleIndices);

  selectedArea.plane.geometry.setAttribute(
    "position",
    new THREE.BufferAttribute(vertices, 3)
  );
  selectedArea.plane.geometry.setIndex(
    new THREE.Uint16BufferAttribute(indices, 1)
  );
  selectedArea.plane.geometry.computeBoundingBox();
  selectedArea.plane.geometry.computeBoundingSphere();

  selectedArea.indices = indices;

  this.updateClosedLinePosition({
    line: selectedArea.combinedLine,
    points: selectedArea.points,
  });

  if (!mergedPoints) {
    const firstLineIndex =
      pointIndex - 1 < 0 ? selectedArea.lines.length - 1 : pointIndex - 1;
    const secondLineIndex = pointIndex;
    const newPoint = selectedArea.points[pointIndex];

    selectedArea.lines[firstLineIndex].secondPoint = newPoint;
    selectedArea.lines[secondLineIndex].firstPoint = newPoint;

    const linesToUpdate = [
      {
        line: selectedArea.lines[firstLineIndex],
        index: firstLineIndex,
      },
      {
        line: selectedArea.lines[secondLineIndex],
        index: secondLineIndex,
      },
    ];

    for (let lineToUpdate of linesToUpdate) {
      const line = lineToUpdate.line;
      const index = lineToUpdate.index;

      this.updateMidPointPosition(
        selectedArea.midpointInstancedMesh,
        index,
        line.firstPoint.position,
        line.secondPoint.position
      );
    }

    // snap dragged point to the mesh
    const cameraPosition = this.camera.position.clone();
    const pointPosition = newPoint.position.clone();
    const rayDirection = pointPosition.sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, rayDirection);

    const intersects = this.raycaster.intersectObject(
      this.modelObject.children[0]
    );

    if (intersects.length > 0) {
      this.draggedAreaPoint.prevPosition = {
        x: newPoint.position.x,
        y: newPoint.position.y,
        z: newPoint.position.z,
      };
    }
  }

  return selectedArea;
};

// Function to check if two line segments intersect
const doLinesIntersect = function (p1, p2, p3, p4) {
  const d1 = new THREE.Vector2(p2.x - p1.x, p2.y - p1.y);
  const d2 = new THREE.Vector2(p4.x - p3.x, p4.y - p3.y);
  const d3 = new THREE.Vector2(p1.x - p3.x, p1.y - p3.y);

  const denom = d1.x * d2.y - d2.x * d1.y;
  if (denom === 0) return false; // Lines are parallel

  const ua = (d2.x * d3.y - d2.y * d3.x) / denom;
  const ub = (d1.x * d3.y - d1.y * d3.x) / denom;

  return ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1;
};

export const calculateSurfaceArea = function (area) {
  if (!area.closed) return 0;

  const points = area.points;
  const areaVertices = [];
  for (let i = 0; i < points.length; i++) {
    areaVertices.push(
      new THREE.Vector3(
        points[i].position.x,
        points[i].position.y,
        points[i].position.z
      )
    );
  }

  let surfaceArea = 0;

  const triangles = [];
  for (let i = 0; i < area.indices.length; i++) {
    if (triangles.length > 0 && triangles[triangles.length - 1].length < 3) {
      triangles[triangles.length - 1].push(areaVertices[area.indices[i]]);
    } else {
      triangles.push([areaVertices[area.indices[i]]]);
    }
  }

  // Triangulate the points to form individual triangles
  for (let triangle of triangles) {
    const [v1, v2, v3] = triangle;

    // Calculate the area of the triangle formed by the vertices
    const triangleArea = this.calculateTriangleArea(v1, v2, v3);

    // Add the triangle area to the total surface area
    surfaceArea += triangleArea;
  }

  return surfaceArea.toFixed(2);
};

export const detectSolarAreaIntersection = function (event) {
  this.setMousePosition(event);

  const solarObjects = [];
  this.areas
    .filter((area) => area.closed)
    .forEach((area) => {
      solarObjects.push(area.plane);
      solarObjects.push(area.pointInstancedMesh);
      solarObjects.push(area.midpointInstancedMesh);
      solarObjects.push(area.combinedLine);

      const closedRestrictedAreas = area.restrictedAreas.filter(
        (ra) => ra.closed
      );
      for (let ra of closedRestrictedAreas) {
        solarObjects.push(ra.instancedMesh);
      }
    });

  let intersects = this.raycaster.intersectObjects(solarObjects);

  if (intersects.length > 0) {
    this.changeCursorToPointer();
  } else {
    if (this.selectedArea) this.restoreDefaultCursor();
    else this.changeCursorToCrosshair();
  }
};

export const detectSolarAreaPointsIntersection = function (event) {
  if (!this.selectedArea) return;
  this.setMousePosition(event);

  const solarObjects = [];
  solarObjects.push(this.selectedArea.pointInstancedMesh);
  solarObjects.push(this.selectedArea.midpointInstancedMesh);

  this.selectedArea.restrictedAreas
    .filter((restrictedArea) => restrictedArea.closed)
    .forEach((restrictedArea) => {
      solarObjects.push(restrictedArea.instancedMesh);
    });

  let intersects = this.raycaster.intersectObjects(solarObjects);

  if (intersects.length > 0) {
    this.changeCursorToPointer();
  } else {
    this.restoreDefaultCursor();
  }
};

export const removeLastPoint = function (event) {
  const key = event.key;
  if (key !== "Backspace" && key !== "Delete") return;

  if (this.areas.length === 0) return;
  const currentArea = this.areas[this.areas.length - 1];
  if (currentArea.points.length === 0 || currentArea.closed) return;
  if (currentArea.points.length === 1) {
    this.removeUnfinshedArea();
  } else {
    this.undo();
  }
};

export const getAreaFromPoint = function (point) {
  for (let area of this.areas) {
    for (let i = 0; i < area.points.length; i++) {
      let areaPoint = area.points[i];
      if (areaPoint.uuid === point.uuid) return area;
    }
  }
};

export const createUpdateSolarGroup = async function (area) {
  if (this.sample || this.anonymousUser) return;
  const solarGroup = {
    projectId: Number(this.projectId),
    position: area.points.map((point) => point.position),
    panelId: area.panelId,
    horizontal_panel_spacing: area.horizontalSpacing,
    vertical_panel_spacing: area.verticalSpacing,
    margin: area.margin,
    offset: area.offset,
    orientation: area.orientation ? "vertical" : "horizontal",
    initial_panel_position: area.startPosition || null,
    original_panel_position: area.originalPosition,
    panels: area.instancedMesh ? area.instancedMesh.instancesCount : 0,
    iteration: area.iteration,
    normal_vector: area.normal,
  };
  if (area.id) {
    solarGroup.id = area.id;
    if (area.currentRotation)
      solarGroup.panel_rotation = area.currentRotation.toFixed(3);
    return await API.airteam3DViewer.updateSolarGroupObject(solarGroup);
  }
  const response = await API.airteam3DViewer.createSolarGroupObject(solarGroup);
  area.id = response.data;
  this.solarGroupActivate();
};

export const convertUserDefaultValuesFromResponse = (data) => {
  return {
    horizontalSpacing: convertToCentimeters(data.horizontal_spacing),
    verticalSpacing: convertToCentimeters(data.vertical_spacing),
    margin: convertToCentimeters(data.panel_margin),
    offset: convertToCentimeters(data.offset),
    orientation: data.orientation === "vertical",
    panelId: data.panel_id,
  };
};

export const getUserDefaultSolarValues = async function () {
  const response = await API.airteam3DViewer.getDefaultValues();
  this.solarDefaultValues = convertUserDefaultValuesFromResponse(response.data);
};

export const getUserLikedPanel = async function () {
  const response = await API.airteam3DViewer.getLikedPanel();
  if (response.data?.panelId) {
    this.likedPanelId = response.data?.panelId;
  }
};

export const useDefaultSolarValues = function (area) {
  area.horizontalSpacing = DEFAULT_HORIZONTAL_SPACING;
  area.verticalSpacing = DEFAULT_VERTICAL_SPACING;
  area.panelId = this.horizontalPanelTypes[0].id;
  area.margin = DEFAULT_MARGIN;
  area.offset = DEFAULT_OFFSET;
  area.orientation = true;
};

export const useStoredSolarValues = function (area, storedArea) {
  area.horizontalSpacing = storedArea.horizontal_panel_spacing;
  area.normal = storedArea.normal_vector;
  area.verticalSpacing = storedArea.vertical_panel_spacing;
  area.panelId = storedArea.panelId;
  area.margin = storedArea.margin;
  area.offset = storedArea.offset;
  area.orientation = storedArea.orientation === "vertical";
  area.iteration = storedArea.iteration;
  if (storedArea.initial_panel_position) {
    area.startPosition = roundVector(storedArea.initial_panel_position);
  }
  if (storedArea.original_panel_position) {
    area.originalPosition = roundVector(storedArea.original_panel_position);
  } else if (storedArea.initial_panel_position) {
    area.originalPosition = roundVector(storedArea.initial_panel_position);
  }
  if (storedArea.panel_rotation) {
    area.currentRotation = parseFloat(storedArea.panel_rotation);
  }
  if (storedArea.individual_panels) {
    area.individual_panels = storedArea.individual_panels;
  }
};

export const isAngleInRange = function (angle) {
  if (angle > this.upperAngleRange) return false;
  if (angle < this.lowerAngleRange) return false;
  return true;
};

export const setAngleInRange = function (angle) {
  if (angle > this.upperAngleRange) return this.upperAngleRange;
  if (angle < this.lowerAngleRange) return this.lowerAngleRange;
  return angle;
};

export const checkPanelNumberAndVisibility = function (selectedArea) {
  if (selectedArea && selectedArea.moveGridLabel) {
    if (this.areaHasPanels(selectedArea) && this.active !== 6) {
      selectedArea.rotateGridLabel.visible = true;

      if (this.areaHasGridPanels(selectedArea))
        selectedArea.moveGridLabel.visible = true;
    } else {
      selectedArea.moveGridLabel.visible = false;
      selectedArea.rotateGridLabel.visible = false;
    }
  }
};

export const areaHasPanels = function (area) {
  if (
    area.instancedMesh &&
    area.instancedMesh.visibilityArray.some((visibility) => visibility === true)
  )
    return true;
  if (
    area.verticalPanelInstancedMesh &&
    area.verticalPanelInstancedMesh.visibilityArray.some(
      (visibility) => visibility === true
    )
  )
    return true;
  if (
    area.horizontalPanelInstancedMesh &&
    area.horizontalPanelInstancedMesh.visibilityArray.some(
      (visibility) => visibility === true
    )
  )
    return true;
};

export const areaHasGridPanels = function (area) {
  if (
    area.instancedMesh &&
    area.instancedMesh.visibilityArray.some((visibility) => visibility === true)
  )
    return true;

  return false;
};

export const controlSpacingBetweenLabels = function () {
  const moveGridLabel = this.selectedArea?.moveGridLabel;
  const rotateGridLabel = this.selectedArea?.rotateGridLabel;

  if (!moveGridLabel || !rotateGridLabel) return;

  const centerPoint = this.selectedArea.panelsCenter;

  const cameraDistance = this.camera.position.distanceTo(centerPoint);
  let zoomFactor = Math.max(1, cameraDistance / 15);

  if (cameraDistance < 16) {
    zoomFactor = 1;
  }

  moveGridLabel.labelOffset = this.selectedArea.horizontalVector
    .clone()
    .multiplyScalar(-0.4 * zoomFactor);

  const moveLabelPosition = new THREE.Vector3()
    .copy(centerPoint)
    .add(moveGridLabel.labelOffset);
  moveGridLabel.position.set(
    moveLabelPosition.x,
    moveLabelPosition.y,
    moveLabelPosition.z
  );

  rotateGridLabel.labelOffset = this.selectedArea.horizontalVector
    .clone()
    .multiplyScalar(0.4 * zoomFactor);
  const rotateLabelPosition = new THREE.Vector3()
    .copy(moveGridLabel.position)
    .add(rotateGridLabel.labelOffset)
    .add(rotateGridLabel.labelOffset);
  rotateGridLabel.position.set(
    rotateLabelPosition.x,
    rotateLabelPosition.y,
    rotateLabelPosition.z
  );
};

export const getAreaPanelsCount = function (area) {
  if (area.type === "AREA") return area.panels.length || 0;
  if (!area.instancedMesh) return 0;
  let totalCount = area.instancedMesh.visibilityArray.filter(
    (v) => v === true
  ).length;
  if (area.verticalPanelInstancedMesh) {
    totalCount += area.verticalPanelInstancedMesh.visibilityArray.filter(
      (v) => v === true
    ).length;
  }
  if (area.horizontalPanelInstancedMesh) {
    totalCount += area.horizontalPanelInstancedMesh.visibilityArray.filter(
      (v) => v === true
    ).length;
  }
  return totalCount;
};

export const enableDragIndividualPanel = function () {
  document.addEventListener("mousedown", this.dragIndividualPanelStart, false);
};

export const disableDragIndividualPanel = function () {
  document.removeEventListener(
    "mousedown",
    this.dragIndividualPanelStart,
    false
  );
};
