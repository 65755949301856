import { createRouter, createWebHistory } from "vue-router";
import AirteamViewer from "../components/AirteamViewer.vue";

const routes = [
  {
    path: "/",
    component: AirteamViewer,
  },
  {
    path: "/sample",
    redirect: "/sample-1",
  },
  {
    path: "/sample-1",
    component: AirteamViewer,
    props: { sample: true, thermal: false },
  },
  {
    path: "/sample-2",
    component: AirteamViewer,
    props: { sample: true, thermal: false },
  },
  {
    path: "/sample-3",
    component: AirteamViewer,
    props: { sample: true, thermal: false },
  },
  {
    path: "/sample-4",
    component: AirteamViewer,
    props: { sample: true, thermal: false },
  },
  {
    path: "/thermal",
    component: AirteamViewer,
    props: { sample: true, thermal: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
